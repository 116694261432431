/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';
export const fetchCategoryGraphData = createAsyncThunk(
  'category/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    // 最後に非ゼロのカテゴリを見つけ、その位置を階層として計算
    const findLastLevel = (category) => {
      // 0 や null ではない、最後の有効なカテゴリを見つける
      const nonZeroCategory = category.filter(
        (item) => item !== 0 && item !== null && item !== undefined
      );
      return nonZeroCategory.length; // 有効なカテゴリの数を階層にする
    };

    // それぞれのカテゴリの階層を計算
    const amazonLevel = findLastLevel([
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6
    ]);
    const rakutenLevel = findLastLevel([
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5
    ]);
    const yahooLevel = findLastLevel([y_cate1, y_cate2, y_cate3, y_cate4]);

    // channel に応じてどのカテゴリの level を渡すか決定
    let categoryLevel = 0;
    if (channel === 1) {
      categoryLevel = amazonLevel;
    } else if (channel === 2) {
      categoryLevel = rakutenLevel;
    } else if (channel === 3) {
      categoryLevel = yahooLevel;
    }
    const res = await client.post(`${apiUrl}category/sales`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel,
      category_level: categoryLevel
    });
    return res.data;
  }
);
export const fetchAllCategoryGraphData = createAsyncThunk(
  'allCategory/post',
  async (_, thunkAPI) => {
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}category/initial_sales`, {
      term: yearMonth,
      channel: channel
    });
    return res.data;
  }
);

export const fetchParentFavoriteCategory = createAsyncThunk(
  'get_parent_favorite_category/post',
  async (arg, thunkAPI) => {
    const { categoryId } = arg;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await client.post(
      `${apiUrl}category/return_parent_favorite_category`,
      {
        category_id: categoryId,
        channel: channel
      }
    );
    return res.data;
  }
);

const fetchCategoryGraphSlice = createSlice({
  name: 'categorygraph',
  initialState: {
    amazon_graphdata: {
      graphdata: [],
      totalsalesData: '',
      totalunitData: '',
      selectCategoryLabel: '',
      lastmonthRate: '',
      lastyearRate: ''
    },
    rakuten_graphdata: {
      graphdata: [],
      totalsalesData: '',
      totalunitData: '',
      selectCategoryLabel: '',
      lastmonthRate: '',
      lastyearRate: ''
    },
    yahoo_graphdata: {
      graphdata: [],
      totalsalesData: '',
      totalunitData: '',
      selectCategoryLabel: '',
      lastmonthRate: '',
      lastyearRate: ''
    },
    parentFavoriteCategoryAmazon: [],
    parentFavoriteCategoryRakuten: [],
    parentFavoriteCategoryYahoo: [],
    a_cate1: 0,
    a_cate2: 0,
    a_cate3: 0,
    a_cate4: 0,
    a_cate5: 0,
    a_cate6: 0,
    r_cate1: 0,
    r_cate2: 0,
    r_cate3: 0,
    r_cate4: 0,
    r_cate5: 0,
    y_cate1: 0,
    y_cate2: 0,
    y_cate3: 0,
    y_cate4: 0
  },
  reducers: {
    // すべてのカテゴリをリセットするアクション
    resetCategories(state) {
      return {
        ...state,
        a_cate1: 0,
        a_cate2: 0,
        a_cate3: 0,
        a_cate4: 0,
        a_cate5: 0,
        a_cate6: 0,
        r_cate1: 0,
        r_cate2: 0,
        r_cate3: 0,
        r_cate4: 0,
        r_cate5: 0,
        y_cate1: 0,
        y_cate2: 0,
        y_cate3: 0,
        y_cate4: 0
      };
    },
    changeAcate1(state, action) {
      return {
        ...state,
        a_cate1: action.payload,
        a_cate2: 0,
        a_cate3: 0,
        a_cate4: 0,
        a_cate5: 0,
        a_cate6: 0
      };
    },
    changeAcate2(state, action) {
      return {
        ...state,
        a_cate2: action.payload,
        a_cate3: 0,
        a_cate4: 0,
        a_cate5: 0,
        a_cate6: 0
      };
    },
    changeAcate3(state, action) {
      return {
        ...state,
        a_cate3: action.payload,
        a_cate4: 0,
        a_cate5: 0,
        a_cate6: 0
      };
    },
    changeAcate4(state, action) {
      return {
        ...state,
        a_cate4: action.payload,
        a_cate5: 0,
        a_cate6: 0
      };
    },
    changeAcate5(state, action) {
      return {
        ...state,
        a_cate5: action.payload,
        a_cate6: 0
      };
    },
    changeAcate6(state, action) {
      return {
        ...state,
        a_cate6: action.payload
      };
    },
    changeRcate1(state, action) {
      return {
        ...state,
        r_cate1: action.payload,
        r_cate2: 0,
        r_cate3: 0,
        r_cate4: 0,
        r_cate5: 0
      };
    },
    changeRcate2(state, action) {
      return {
        ...state,
        r_cate2: action.payload,
        r_cate3: 0,
        r_cate4: 0,
        r_cate5: 0
      };
    },
    changeRcate3(state, action) {
      return {
        ...state,
        r_cate3: action.payload,
        r_cate4: 0,
        r_cate5: 0
      };
    },
    changeRcate4(state, action) {
      return {
        ...state,
        r_cate4: action.payload,
        r_cate5: 0
      };
    },
    changeRcate5(state, action) {
      return {
        ...state,
        r_cate5: action.payload
      };
    },
    changeYcate1(state, action) {
      return {
        ...state,
        y_cate1: action.payload,
        y_cate2: 0,
        y_cate3: 0,
        y_cate4: 0
      };
    },
    changeYcate2(state, action) {
      return {
        ...state,
        y_cate2: action.payload,
        y_cate3: 0,
        y_cate4: 0
      };
    },
    changeYcate3(state, action) {
      return {
        ...state,
        y_cate3: action.payload,
        y_cate4: 0
      };
    },
    changeYcate4(state, action) {
      return {
        ...state,
        y_cate4: action.payload
      };
    },
    resetAgraph(state, action) {
      return {
        ...state,
        amazon_graphdata: {
          graphdata: [],
          totalsalesData: '',
          totalunitData: '',
          selectCategoryLabel: '',
          lastmonthRate: '',
          lastyearRate: ''
        }
      };
    },
    resetRgraph(state, action) {
      return {
        ...state,
        rakuten_graphdata: {
          graphdata: [],
          totalsalesData: '',
          totalunitData: '',
          selectCategoryLabel: '',
          lastmonthRate: '',
          lastyearRate: ''
        }
      };
    },
    resetYgraph(state, action) {
      return {
        ...state,
        yahoo_graphdata: {
          graphdata: [],
          totalsalesData: '',
          totalunitData: '',
          selectCategoryLabel: '',
          lastmonthRate: '',
          lastyearRate: ''
        }
      };
    },
    resetParentFavorite(state, action) {
      state.parentFavoriteCategoryAmazon = [];
      state.parentFavoriteCategoryRakuten = [];
      state.parentFavoriteCategoryYahoo = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryGraphData.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.amazon_graphdata['graphdata'] = action.payload.graphdata;
        state.amazon_graphdata['totalsalesData'] = action.payload.sales;
        state.amazon_graphdata['totalunitData'] = action.payload.unit_sold;
        state.amazon_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.amazon_graphdata['lastmonthRate'] = action.payload.lastmonth_rate;
        state.amazon_graphdata['lastyearRate'] = action.payload.lastyear_rate;
        state.isLoading = false;
      } else if (action.payload.channel == 2) {
        state.rakuten_graphdata['graphdata'] = action.payload.graphdata;
        state.rakuten_graphdata['totalsalesData'] = action.payload.sales;
        state.rakuten_graphdata['totalunitData'] = action.payload.unit_sold;
        state.rakuten_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.rakuten_graphdata['lastmonthRate'] =
          action.payload.lastmonth_rate;
        state.rakuten_graphdata['lastyearRate'] = action.payload.lastyear_rate;
        state.isLoading = false;
      } else if (action.payload.channel == 3) {
        state.yahoo_graphdata['graphdata'] = action.payload.graphdata;
        state.yahoo_graphdata['totalsalesData'] = action.payload.sales;
        state.yahoo_graphdata['totalunitData'] = action.payload.unit_sold;
        state.yahoo_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.yahoo_graphdata['lastmonthRate'] = action.payload.lastmonth_rate;
        state.yahoo_graphdata['lastyearRate'] = action.payload.lastyear_rate;
        state.isLoading = false;
      }
    });
    builder.addCase(fetchCategoryGraphData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
        // error: false
      };
    });
    builder.addCase(fetchCategoryGraphData.rejected, (state, action) => {
      return {
        ...state,
        graphdata: [0, 0, 0],
        totalsalesData: [0, 0, 0],
        totalunitData: [0, 0, 0],
        selectCategoryLabel: ['', '', ''],
        lastmonthRate: [0, 0, 0],
        lastyearRate: [0, 0, 0],
        isLoading: false
        // error: false
      };
    });
    builder.addCase(fetchAllCategoryGraphData.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.amazon_graphdata['graphdata'] = action.payload.graphdata;
        state.amazon_graphdata['totalsalesData'] = action.payload.sales;
        state.amazon_graphdata['totalunitData'] = action.payload.unit_sold;
        state.amazon_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.amazon_graphdata['lastmonthRate'] = '';
        state.amazon_graphdata['lastyearRate'] = '';
        state.isLoading = false;
      } else if (action.payload.channel == 2) {
        state.rakuten_graphdata['graphdata'] = action.payload.graphdata;
        state.rakuten_graphdata['totalsalesData'] = action.payload.sales;
        state.rakuten_graphdata['totalunitData'] = action.payload.unit_sold;
        state.rakuten_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.rakuten_graphdata['lastmonthRate'] = '';
        state.rakuten_graphdata['lastyearRate'] = '';
        state.isLoading = false;
      } else if (action.payload.channel == 3) {
        state.yahoo_graphdata['graphdata'] = action.payload.graphdata;
        state.yahoo_graphdata['totalsalesData'] = action.payload.sales;
        state.yahoo_graphdata['totalunitData'] = action.payload.unit_sold;
        state.yahoo_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.yahoo_graphdata['lastmonthRate'] = '';
        state.yahoo_graphdata['lastyearRate'] = '';
        state.isLoading = false;
      }
    });
    builder.addCase(fetchAllCategoryGraphData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
        // error: false
      };
    });
    builder.addCase(fetchAllCategoryGraphData.rejected, (state, action) => {
      return {
        ...state,
        graphdata: [0, 0, 0],
        totalsalesData: [0, 0, 0],
        totalunitData: [0, 0, 0],
        selectCategoryLabel: ['', '', ''],
        lastmonthRate: [0, 0, 0],
        lastyearRate: [0, 0, 0],
        isLoading: false
        // error: false
      };
    });
    builder.addCase(fetchParentFavoriteCategory.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.parentFavoriteCategoryAmazon =
          action.payload.parentFavorite_categories_amazon;
        state.parentFavoriteCategoryRakuten =
          state.parentFavoriteCategoryRakuten;
        state.parentFavoriteCategoryYahoo = state.parentFavoriteCategoryYahoo;

        state.a_cate1 = action.payload.parentFavorite_categories_amazon[0];
        state.a_cate2 = action.payload.parentFavorite_categories_amazon[1];
        state.a_cate3 = action.payload.parentFavorite_categories_amazon[2];
        state.a_cate4 = action.payload.parentFavorite_categories_amazon[3];
        state.a_cate5 = action.payload.parentFavorite_categories_amazon[4];
        state.a_cate6 = action.payload.parentFavorite_categories_amazon[5];
      } else if (action.payload.channel == 2) {
        state.parentFavoriteCategoryAmazon = state.parentFavoriteCategoryAmazon;
        state.parentFavoriteCategoryRakuten =
          action.payload.parentFavorite_categories_rakuten;
        state.parentFavoriteCategoryYahoo = state.parentFavoriteCategoryYahoo;

        state.r_cate1 = action.payload.parentFavorite_categories_rakuten[0];
        state.r_cate2 = action.payload.parentFavorite_categories_rakuten[1];
        state.r_cate3 = action.payload.parentFavorite_categories_rakuten[2];
        state.r_cate4 = action.payload.parentFavorite_categories_rakuten[3];
        state.r_cate5 = action.payload.parentFavorite_categories_rakuten[4];
      } else if (action.payload.channel == 2) {
        state.parentFavoriteCategoryAmazon = state.parentFavoriteCategoryAmazon;
        state.parentFavoriteCategoryRakuten =
          state.parentFavoriteCategoryRakuten;
        state.parentFavoriteCategoryYahoo =
          action.payload.parentFavorite_categories_yahoo;
        state.y_cate1 = action.payload.parentFavorite_categories_yahoo[0];
        state.y_cate2 = action.payload.parentFavorite_categories_yahoo[1];
        state.y_cate3 = action.payload.parentFavorite_categories_yahoo[2];
        state.y_cate4 = action.payload.parentFavorite_categories_yahoo[3];
        state.y_cate5 = action.payload.parentFavorite_categories_yahoo[4];
      }
    });
  }
});

export const selectAmazonCategoryGraph = (state) =>
  state.categorygraph.amazon_graphdata;
export const selectRakutenCategoryGraph = (state) =>
  state.categorygraph.rakuten_graphdata;
export const selectYahooCategoryGraph = (state) =>
  state.categorygraph.yahoo_graphdata;

export const selectCategoryFigures = (state) =>
  state.categorygraph.salesFiguresData;
export const currentAcate1 = (state) => state.categorygraph.a_cate1;
export const currentAcate2 = (state) => state.categorygraph.a_cate2;
export const currentAcate3 = (state) => state.categorygraph.a_cate3;
export const currentAcate4 = (state) => state.categorygraph.a_cate4;
export const currentAcate5 = (state) => state.categorygraph.a_cate5;
export const currentAcate6 = (state) => state.categorygraph.a_cate6;
export const currentAmazonCategoryList = (state) => [
  state.categorygraph.a_cate1,
  state.categorygraph.a_cate2,
  state.categorygraph.a_cate3,
  state.categorygraph.a_cate4,
  state.categorygraph.a_cate5,
  state.categorygraph.a_cate6
];
export const currentRcate1 = (state) => state.categorygraph.r_cate1;
export const currentRcate2 = (state) => state.categorygraph.r_cate2;
export const currentRcate3 = (state) => state.categorygraph.r_cate3;
export const currentRcate4 = (state) => state.categorygraph.r_cate4;
export const currentRcate5 = (state) => state.categorygraph.r_cate5;
export const currentRakutenCategoryList = (state) => [
  state.categorygraph.r_cate1,
  state.categorygraph.r_cate2,
  state.categorygraph.r_cate3,
  state.categorygraph.r_cate4,
  state.categorygraph.r_cate5
];
export const currentYcate1 = (state) => state.categorygraph.y_cate1;
export const currentYcate2 = (state) => state.categorygraph.y_cate2;
export const currentYcate3 = (state) => state.categorygraph.y_cate3;
export const currentYcate4 = (state) => state.categorygraph.y_cate4;
export const currentYahooCategoryList = (state) => [
  state.categorygraph.y_cate1,
  state.categorygraph.y_cate2,
  state.categorygraph.y_cate3,
  state.categorygraph.y_cate4
];
export const categoryLabel = (state) => state.categorygraph.selectCategoryLabel;
export const selectSalesFigures = (state) => state.categorygraph.totalsalesData;
export const selectUnitFigures = (state) => state.categorygraph.totalunitData;
export const selectLastMonthRate = (state) => state.categorygraph.lastmonthRate;
export const selectLastYearRate = (state) => state.categorygraph.lastyearRate;
export const categorygraphStatus = (state) => state.categorygraph.isLoading;

export const amazonParentFavoriteCategory = (state) =>
  state.categorygraph.parentFavoriteCategoryAmazon;
export const rakutenParentFavoriteCategory = (state) =>
  state.categorygraph.parentFavoriteCategoryRakuten;
export const yahooParentFavoriteCategory = (state) =>
  state.categorygraph.parentFavoriteCategoryYahoo;

export const {
  changeAcate1,
  changeAcate2,
  changeAcate3,
  changeAcate4,
  changeAcate5,
  changeAcate6,
  changeRcate1,
  changeRcate2,
  changeRcate3,
  changeRcate4,
  changeRcate5,
  changeYcate1,
  changeYcate2,
  changeYcate3,
  changeYcate4,
  resetAgraph,
  resetRgraph,
  resetYgraph,
  resetParentFavorite,
  resetCategories
} = fetchCategoryGraphSlice.actions;
export const { changeTerm } = fetchCategoryGraphSlice.actions;
export default fetchCategoryGraphSlice.reducer;

/* eslint-disable*/
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  IconButton,
  Box,
  Button,
  Link,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  FormGroup,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress
} from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { fetchRegister, fetchUser } from 'feactures/user/userSlice';
import { errorResMessage, errorResStatus } from 'feactures/errorSlice';
import { makeStyles } from '@material-ui/styles';
import { createSubscriptionSession } from 'feactures/plan/planSlice';
import {
  fetchIndustry,
  fetchPremiumRegister,
  industryList
} from 'feactures/plan/premiumRegisterSlice';
import StickyButton from 'components/atoms/button/StickyButton';

const PremiumRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector(errorResMessage);
  const industries = useSelector(industryList);
  const [error, setError] = useState(null);

  // パスワードの表示・非表示処理
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    const payment_method = values.paymentMethod;
    const mall_count = values.ecMall.length; // 申し込みモール数
    const mall = //申し込みモール　両方ならboth
      values.ecMall.includes('1') && values.ecMall.includes('2')
        ? 'both'
        : values.ecMall.includes('1')
        ? 'amazon'
        : values.ecMall.includes('2')
        ? 'rakuten'
        : '';

    let navigate_url = '';
    navigate_url = `/register_new_premium/complete?mall=${mall}&mall_count=${mall_count}&payment_method=${payment_method}`;

    // console.log(values);
    setError(null);
    await dispatch(fetchPremiumRegister(values))
      .then(unwrapResult)
      .then(() => {
        navigate(navigate_url, { replace: false });
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const RequiredBox = () => {
    return (
      <Box
        color="#FFFFFF"
        bgcolor="#F26B6B"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        mb="1px"
        mr="8px"
        width="40px"
        height="23px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        必須
      </Box>
    );
  };
  const OptionBox = () => {
    return (
      <Box
        color="#ffffff"
        bgcolor="#669AF5"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        任意
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <Formik
          initialValues={{
            ecMall: [],
            userId: '',
            password: '',
            paymentMethod: '',
            legalStatus: '',
            taxType: ''
          }}
          validationSchema={Yup.object().shape({
            ecMall: Yup.array()
              .min(1, '選択してください')
              .of(Yup.string().required()),
            userId: Yup.string().max(255).required('ユーザーIDが未入力です'),
            password: Yup.string().max(100).required('パスワードが未入力です'),
            paymentMethod: Yup.string().required('決済方法を選択してください'),
            legalStatus: Yup.string().required('選択してください'),
            taxType: Yup.string().required('選択してください')
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 3, mt: 3 }}>
                <Typography color="textPrimary" variant="h2">
                  Oxcim有料版利用申し込みフォーム
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  <br />
                  Oxcim【有料版】を利用申し込みする場合は下記よりお願いいたします。
                  <br />
                  3営業日以内にカスタマーサポ―トからご案内いたします。
                </Typography>
              </Box>
              <Box alignItems="center">
                <InputLabel>
                  <Box display="flex">
                    <RequiredBox />
                    <Typography fontSize="16px">
                      利用モール（1モールにつき、55,000円(税込)/月）
                    </Typography>
                  </Box>
                  <Typography fontSize={'12px'}>※半年契約となります</Typography>
                  <Box>
                    {errors.ecMall && (
                      <FormHelperText sx={{ m: 0, ml: 1 }} error>
                        {errors.ecMall}
                      </FormHelperText>
                    )}
                  </Box>
                </InputLabel>
                <FormControl
                  error={Boolean(touched.ecMall && errors.ecMall)}
                  sx={{ width: '100%', mb: 2 }}
                >
                  <FormGroup
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      width: '100%'
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="1"
                          onChange={handleChange}
                          name="ecMall"
                          checked={values.ecMall.includes('1')}
                        />
                      }
                      label="Amazon"
                      sx={{
                        flexBasis: 'calc(33.33% - 10px)',
                        ml: '-11',
                        mr: '5px'
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="2"
                          onChange={handleChange}
                          name="ecMall"
                          checked={values.ecMall.includes('2')}
                        />
                      }
                      label="楽天"
                      sx={{
                        flexBasis: 'calc(33.33% - 10px)',
                        ml: '-11',
                        mr: '5px'
                      }}
                    />
                    {/* <FormControlLabel
                        control={
                          <Checkbox
                            value="3"
                            onChange={handleChange}
                            name="ecMall"
                            disabled={true}
                          />
                        }
                        label="Yahoo"
                        sx={{
                          flexBasis: 'calc(33.33% - 10px)',
                          ml: '-11',
                          mr: '5px'
                        }}
                      /> */}
                  </FormGroup>
                </FormControl>
                <Box display="flex" alignItems="center">
                  <InputLabel>
                    <Box display="flex" alignItems="center">
                      <RequiredBox />
                      OxcimユーザーID
                      {touched.userId && errors.userId && (
                        <FormHelperText sx={{ m: 0, ml: 1 }} error>
                          {errors.userId}
                        </FormHelperText>
                      )}
                    </Box>
                  </InputLabel>
                </Box>
                <TextField
                  sx={{ mb: 2, backgroundColor: '#fff', width: 440 }}
                  error={Boolean(touched.userId && errors.userId)}
                  fullWidth
                  //   helperText={touched.userId && errors.userId}
                  name="userId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.userId}
                  variant="outlined"
                  size="small"
                  autoComplete="new-user-id"
                  inputProps={{ autoComplete: 'off' }}
                />
                <Box display="flex" alignItems="center">
                  <InputLabel>
                    <Box display="flex" alignItems="center">
                      <RequiredBox />
                      Oxcimパスワード
                      {touched.password && errors.password && (
                        <FormHelperText sx={{ m: 0, ml: 1 }} error>
                          {errors.password}
                        </FormHelperText>
                      )}
                    </Box>
                  </InputLabel>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  sx={{ mb: 2, backgroundColor: '#fff' }}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  variant="outlined"
                  size="small"
                  autoComplete="new-password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    autoComplete: 'off',
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    )
                  }}
                />
                <InputLabel component="legend">
                  <Box display="flex" alignItems="center" mt={1}>
                    <RequiredBox />
                    <Box display="flex" alignItems="center">
                      決済方法
                      {errors.paymentMethod && (
                        <FormHelperText sx={{ m: 0, ml: 1 }} error>
                          {errors.paymentMethod}
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                </InputLabel>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="paymentMethod"
                    value={values.paymentMethod}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="カード決済"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="銀行振込"
                    />
                  </RadioGroup>
                </FormControl>
                <InputLabel component="legend">
                  <Box display="flex" alignItems="center" mt={1}>
                    <RequiredBox />
                    <Box display="flex" alignItems="center">
                      事業者区分
                      {errors.legalStatus && (
                        <FormHelperText sx={{ m: 0, ml: 1 }} error>
                          {errors.legalStatus}
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                </InputLabel>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="legalStatus"
                    value={values.legalStatus}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="法人"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="個人事業主"
                    />
                  </RadioGroup>
                </FormControl>

                <InputLabel component="legend">
                  <Box display="flex" alignItems="center" mt={1}>
                    <RequiredBox />
                    <Box display="flex" alignItems="center">
                      税務区分
                      {errors.taxType && (
                        <FormHelperText sx={{ m: 0, ml: 1 }} error>
                          {errors.taxType}
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                </InputLabel>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="taxType"
                    value={values.taxType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="免税事業者"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label="課税事業者"
                    />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ py: 2 }} onSubmit={handleSubmit}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ mb: 1 }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      '申し込む'
                    )}
                  </Button>
                  {error && <Alert severity="error">{errorMessage}</Alert>}
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default PremiumRegister;

/* eslint-disable*/
import { Box, Container, Typography, Modal, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeModalIsOpen,
  closeChangeModal,
  checkoutIsLoading,
  createCheckoutSession,
  createSubscriptionSession
} from 'feactures/plan/planSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid lightGray',
  borderRadius: 1,
  boxShadow: 20,
  px: 4,
  py: 6,
  display: 'flex',
  flexDirection: 'column'
};

const PlanChangeModal = ({ plan_name, plan_id }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(changeModalIsOpen);
  const isLoading = useSelector(checkoutIsLoading);
  const handleClose = () => {
    dispatch(closeChangeModal());
  };

  const handleClick = () => {
    dispatch(
      createCheckoutSession({
        plan_id: plan_id,
        channel: 1,
        period: 1,
        payment_method: 1
      })
    );
  };

  const handleSubscriptionClick = () => {
    dispatch(
      createSubscriptionSession({
        plan_id: plan_id,
        channel: 1,
        period: 1,
        payment_method: 1
      })
    );
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <Typography sx={{ fontSize: '18px', textAlign: 'start', pb: 3 }}>
          {plan_name}プランを購入する
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            onClick={handleClick}
            sx={{ fontSize: '14px', px: 3, width: '45%' }}
            disabled={isLoading}
          >
            1か月プランで購入する
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={handleSubscriptionClick}
            sx={{ fontSize: '14px', px: 3, width: '45%' }}
            disabled={isLoading}
          >
            サブスクリプションで購入する
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PlanChangeModal;

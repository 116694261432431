/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl } from 'App.js';

export const fetchFavoriteCategory = createAsyncThunk(
  'get_favorite_category/post',
  async (arg, thunkAPI) => {
    const { userId, accountId } = thunkAPI.getState().user;
    // console.log('accountId', accountId);
    const res = await axios.post(`${apiUrl}category/get_favorite_category`, {
      account_id: accountId
    });
    return res.data;
  }
);

export const updateFavoriteCategory = createAsyncThunk(
  'update_favorite_category/post',
  async (arg, thunkAPI) => {
    const { category_id, category_name, status } = arg;
    const { userId, accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await axios.post(`${apiUrl}category/update_favorite_category`, {
      account_id: accountId,
      category_id: category_id,
      category_name: category_name,
      channel: channel,
      status: status
    });
    return res.data;
  }
);

const favoriteCategorySlice = createSlice({
  name: 'favoriteCategories',
  initialState: {
    favoriteCategoryAmazon: [],
    favoriteCategoryRakuten: [],
    favoriteCategoryYahoo: [],
    selectedFavoriteCategory: {
      amazon: { label: null, value: null },
      rakuten: { label: null, value: null },
      yahoo: { label: null, value: null }
    },
    isLoadig: false
  },
  reducers: {
    setSelectedFavoriteCategory: (state, action) => {
      if (action.payload.channel == 1) {
        state.selectedFavoriteCategory.amazon = action.payload.cate;
      } else if (action.payload.channel == 2) {
        state.selectedFavoriteCategory.rakuten = action.payload.cate;
      } else if (action.payload.channel == 3) {
        state.selectedFavoriteCategory.yahoo = action.payload.cate;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFavoriteCategory.fulfilled, (state, action) => {
      return {
        ...state,
        favoriteCategoryAmazon: action.payload.favorite_categories_amazon,
        favoriteCategoryRakuten: action.payload.favorite_categories_rakuten,
        favoriteCategoryYahoo: action.payload.favorite_categories_yahoo,
        isLoadig: false
      };
    });
    builder.addCase(fetchFavoriteCategory.pending, (state, action) => {
      return {
        ...state,
        isLoadig: true
      };
    });
    builder.addCase(fetchFavoriteCategory.rejected, (state, action) => {
      return {
        ...state,
        isLoadig: false
      };
    });
    builder.addCase(updateFavoriteCategory.fulfilled, (state, action) => {
      return {
        ...state,
        favoriteCategoryAmazon: action.payload.favorite_categories_amazon,
        favoriteCategoryRakuten: action.payload.favorite_categories_rakuten,
        favoriteCategoryYahoo: action.payload.favorite_categories_yahoo,
        isLoadig: false
      };
    });
    builder.addCase(updateFavoriteCategory.pending, (state, action) => {
      return {
        ...state,
        isLoadig: true
      };
    });
    builder.addCase(updateFavoriteCategory.rejected, (state, action) => {
      return {
        ...state,
        isLoadig: false
      };
    });
  }
});

// export const {
//   selectCategory1
// } = fetchCategoryGraphSlice.actions;

export const amazonFavoriteCategory = (state) =>
  state.favoriteCategories.favoriteCategoryAmazon;
export const rakutenFavoriteCategory = (state) =>
  state.favoriteCategories.favoriteCategoryRakuten;
export const yahooFavoriteCategory = (state) =>
  state.favoriteCategories.favoriteCategoryYahoo;
export const selectedFavoriteCategory = (state) =>
  state.favoriteCategories.selectedFavoriteCategory;
export const selectedFavoriteCategoryAmazon = (state) =>
  state.favoriteCategories.selectedFavoriteCategory.amazon;
export const selectedFavoriteCategoryRakuten = (state) =>
  state.favoriteCategories.selectedFavoriteCategory.rakuten;
export const selectedFavoriteCategoryYahoo = (state) =>
  state.favoriteCategories.selectedFavoriteCategory.yahoo;
export const favoriteCategoryStatus = (state) =>
  state.favoriteCategories.isLoadig;

export const { setSelectedFavoriteCategory } = favoriteCategorySlice.actions;

export default favoriteCategorySlice.reducer;

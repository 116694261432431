/* eslint-disable*/
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import { useEffect, useState, memo, useRef } from 'react';
import { Box, Typography, Link } from '@material-ui/core'; // eslint-disable-line

// 本番環境判定=
const isProduction = window.location.hostname === 'www.oxcim.jp';

/* 新規有料開設フォームでの登録完了画面*/
const StepRegisterNewPremiumComplete = memo(() => {
  const [searchParams] = useSearchParams();

  //   const start = searchParams.get('start');
  const mall = searchParams.get('mall');
  const mall_count = searchParams.get('mall_count');
  const payment_method = searchParams.get('payment_method'); //1:カード決済　2:銀行振込

  let stripe_link = '';
  let total = 0;

  if (mall_count == 1) {
    total = 55000 * 1;
    if (mall == 'amazon') {
      // 今月開始＆モール数1＆月払い
      stripe_link = 'https://buy.stripe.com/aEUdTafIR3fkg1O9B2';
      if (!isProduction) {
        stripe_link = 'https://buy.stripe.com/test_fZeg2k79P53M6GsdR9';
      }
    } else if (mall == 'rakuten') {
      stripe_link = 'https://buy.stripe.com/fZe4iAfIRcPUeXK3cF';
      if (!isProduction) {
        stripe_link = 'https://buy.stripe.com/test_3cs5nG8dT1RA4ykfZi';
      }
    }
  } else if (mall_count == 2 && mall == 'both') {
    //Amazon,楽天両方
    total = 55000 * 2;
    stripe_link = 'https://buy.stripe.com/aEU3ewcwFg267vi00u';
    if (!isProduction) {
      stripe_link = 'https://buy.stripe.com/test_fZe5nG8dTgMu2qccN7';
    }
  }

  useEffect(() => {
    // 5秒後に別のURLに遷移
    const timer = setTimeout(() => {
      if (stripe_link !== '' && payment_method == 1) {
        window.location.replace(stripe_link);
      }
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box xs={12} textAlign="center" backgroundColor="#F7FCFF" pt={5}>
        <Typography variant="h1" fontWeight={'bold'} mb={3}>
          お申し込みありがとうございました。
        </Typography>
        {payment_method == '1' ? (
          <>
            <Typography variant="h4" mb={1}>
              次に、
              <Link href={stripe_link} underline="hover" fontSize="25px">
                カード決済手続き
              </Link>
              をお願いします。
            </Typography>
            <Typography variant="h5" mb={3}>
              画面は5秒後に自動的に決済画面へ遷移しますので、そのままお待ちください。
            </Typography>
            <Typography variant="h5" fontSize={'16px'} pb={3}>
              ※決済手続きが完了次第、カスタマーサポートから利用開始のご案内をいたします。
            </Typography>
          </>
        ) : payment_method == '2' ? (
          <>
            <Typography variant="h5" fontSize={'16px'} mb={1}>
              続いて、入金の手続きをお願いいたします。
            </Typography>
            <Typography variant="h5" fontSize={'16px'} pb={3}>
              ※カスタマーサポートから振込先の記載されたご案内メールをお送りしますので、少々お待ちください。
            </Typography>
            <Box>
              <Typography variant="h6" mb={3}>
                ログイン画面は
                <Link
                  component={RouterLink}
                  to="/login"
                  variant="h5"
                  underline="hover"
                >
                  こちら
                </Link>
              </Typography>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
});

export default StepRegisterNewPremiumComplete;

/* eslint-disable*/
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes, useLocation } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@material-ui/core/styles';
import GlobalStyles from 'components/GlobalStyles';
import theme from 'theme/index';
import routes from 'routes.js';
import { setError } from 'feactures/errorSlice';
import StickyButton from 'components/atoms/button/StickyButton';
import {
  currentchannel,
  currentchannelName
} from 'feactures/channel/channelSlice';

import 'moment/locale/ja';
import { userLoggedIn, userinfo } from 'feactures/user/userSlice';
import { groupListLength } from 'feactures/monitoring/groupSlice';
import NoticeDialog from 'components/atoms/notice/NodiceDialog';
import ConnectionDialog from 'components/atoms/modal/ConnectionDialog';

// export const apiUrl = 'http://52.197.202.214:3000/api/'; //boxwb1
// export const apiUrl = 'http://18.183.158.221:3000/api/'; //web2
// export const apiUrl = 'https://www.oxcim.jp/api/'; //本番
// export const apiUrl = 'api/'; //本番
// export const apiUrl = 'http://127.0.0.1:5000/api/'; //ローカル

// 本番か検証かの環境判定
const isProduction =
  window.location.hostname === 'www.oxcim.jp' || //本番
  window.location.hostname === '52.197.202.214'; //検証

// API URLを環境に応じて設定
export let apiUrl;
if (isProduction) {
  apiUrl = 'api/'; // 本番環境のAPI URL
} else {
  apiUrl = 'http://127.0.0.1:5000/api/'; // 開発環境のAPI URL
}

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(userLoggedIn);
  const channel = useSelector(currentchannel);
  const userInfo = useSelector(userinfo);
  const groupLength = useSelector(groupListLength);
  const [isDataConnected, setIsDataConnected] = useState(0);

  const [
    freePlanRegisterInfosSuccessFlag,
    setFreePlanRegisterInfosSuccessFlag
  ] = useState({
    1: false,
    2: false,
    3: false
  });

  // 無料プランでカテゴリブランドの登録が完了したら、フラグをtrue
  const updateFreePlanRegisterInfosSuccessFlag = () => {
    setFreePlanRegisterInfosSuccessFlag((prevState) => {
      return {
        ...prevState,
        [channel]: true // channelに対応するフラグを更新
      };
    });
  };

  // 有料プランフラグをuseStateで管理
  const [premiumPlan, setPremiumPlan] = useState(false);
  const [selectedBrandStatus, setFreePlanPreselectedBrand] = useState(false);
  const [selectedCategoryStatus, setFreePlanPreselectedCategory] =
    useState(false);

  useEffect(() => {
    if (
      channel === 0 &&
      (userInfo.amazonStatus >= 4 ||
        userInfo.rakutenStatus >= 7 ||
        userInfo.yahooStatus >= 4)
    ) {
      // 'channel=0(全体)で接続済みのモールが１つでもあれば全体ビューは見せる'
      setIsDataConnected(true);
    } else if (channel === 1) {
      setPremiumPlan(userInfo.plan?.amazon.premium);
      setFreePlanPreselectedCategory(
        Boolean(userInfo.freePlanPreselectedItems?.category?.amazon?.value)
      );
      setFreePlanPreselectedBrand(
        Boolean(userInfo.freePlanPreselectedItems?.brand?.amazon?.brand)
      );
      setIsDataConnected(Boolean(userInfo.amazonStatus >= 4)); // データ接続完了４以上であればTrue
    } else if (channel === 2) {
      setPremiumPlan(userInfo.plan?.rakuten.premium);
      setFreePlanPreselectedCategory(
        Boolean(userInfo.freePlanPreselectedItems?.category?.rakuten?.value)
      );
      setFreePlanPreselectedBrand(
        Boolean(userInfo.freePlanPreselectedItems?.brand?.rakuten?.brand)
      );
      setIsDataConnected(Boolean(userInfo.rakutenStatus >= 7)); // データ接続完了7以上であればTrue
    } else if (channel === 3) {
      setPremiumPlan(userInfo.plan?.yahoo.premium);
      setFreePlanPreselectedCategory(
        Boolean(userInfo.freePlanPreselectedItems?.category?.yahoo?.value)
      );
      setFreePlanPreselectedBrand(
        Boolean(userInfo.freePlanPreselectedItems?.brand?.yahoo?.brand)
      );
      setIsDataConnected(Boolean(userInfo.yahooStatus >= 4)); // データ接続完了4以上であればTrue
    }
  }, [
    channel,
    userInfo,
    setPremiumPlan,
    setFreePlanPreselectedBrand,
    setFreePlanPreselectedCategory
  ]);

  const routing = useRoutes(
    routes(
      isLoggedIn,
      groupLength,
      channel,
      premiumPlan,
      selectedBrandStatus,
      selectedCategoryStatus,
      freePlanRegisterInfosSuccessFlag,
      updateFreePlanRegisterInfosSuccessFlag,
      isDataConnected
    )
  );
  const location = useLocation();

  // 画面が遷移したらエラーメッセージを初期化
  useEffect(() => {
    dispatch(setError({ state: null, data: null }));
  }, [location.pathname]);

  const today = new Date();
  const targetDate = new Date(today.getFullYear(), 6, 27);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
        {/* ダイアログの表示 */}
        {userInfo.accountStatus === 0 && <ConnectionDialog />}
        {/* {isLoggedIn && <StickyButton />} */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

/* eslint-disable*/
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  List,
  // Subheader,
  ListItem
} from '@material-ui/core'; /* eslint-disable*/
// import ContentSend from '@material-ui/icons/ContentSend';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { changePageflag } from 'feactures/date/dateSlice';

export const NestedMenu = () => {
  const navigate = useNavigate();
  const [kpiPosition, setKpiPosition] = useState(null);
  const [marketPosition, setMarketPosition] = useState(null);

  const dispatch = useDispatch();

  //KPIボタン押下

  const handleRightClick = (event) => {
    const selectButton = event.currentTarget.value;

    setKpiPosition(null);
    setMarketPosition(null);

    // if (kpiPosition || marketPosition) {
    //   console.log(kpiPosition);
    //   console.log(marketPosition);
    //   return;
    // }

    event.preventDefault();
    if (selectButton == 'kpi') {
      setKpiPosition(event.currentTarget);
    } else if (selectButton == 'market') {
      setMarketPosition(event.currentTarget);
    }
  };

  //listItem押下
  const handleItemClick = (path) => {
    navigate(`/app${path}`);
    // dispatchでpathの中身を渡す→DashboardNavbarで受け取って、マーケットのpathだったら更新ボタンを非表示にする
    dispatch(changePageflag(path));
    setKpiPosition(null);
    setMarketPosition(null);
  };

  return (
    <div>
      <Box
        sx={{
          height: '29px',
          pl: 5,
          flexGrow: 1,
          display: 'flex'
        }}
      >
        <Button
          onClick={handleRightClick}
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            minWidth: '180px'
          }}
          endIcon={<KeyboardArrowDownIcon />}
          value="kpi"
        >
          KPIビュー
        </Button>
        <Button
          onClick={handleRightClick}
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            minWidth: '180px'
          }}
          endIcon={<KeyboardArrowDownIcon />}
          value="market"
        >
          マーケットビュー
        </Button>
        {/* <Button
          onClick={() => navigate(`/app/example`)}
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            minWidth: '180px'
          }}
          value="market"
        >
          アップロード（仮）
        </Button> */}
        {/* <Button
          onClick={() => navigate(`/register/complete`)}
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            minWidth: '180px'
          }}
          value="market"
        >
          アカウント登録完了ページ
        </Button> */}
      </Box>
      <Menu
        open={!!kpiPosition}
        onClose={() => setKpiPosition(null)}
        anchorEl={kpiPosition}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        // anchorReference="anchorPosition"
        // anchorPosition={kpiPosition}
      >
        <MenuItem onClick={() => handleItemClick('/monitoring')}>
          KPIモニタリング
        </MenuItem>
        {/* <MenuItem onClick={() => handleItemClick('/monitoring')}>
          KPIモニタリング
        </MenuItem> */}
        {/* <MenuItem onClick={handleItemClick}>Button 2</MenuItem> */}
        <NestedMenuItem
          sx={{ minWidth: '150px', justifyContent: 'space-between' }}
          label="Sub KPIモニタリング"
          parentMenuOpen={!!kpiPosition}
          //   onClick={handleItemClick}
        >
          <MenuItem onClick={() => handleItemClick('/numberviews')}>
            閲覧数
          </MenuItem>
          <MenuItem onClick={() => handleItemClick('/cvr')}>CVR</MenuItem>
          <MenuItem onClick={() => handleItemClick('/unitprice')}>
            販売価格
          </MenuItem>
          {/* <NestedMenuItem
              label="Sub-Button 3"
              parentMenuOpen={!!menuPosition}
              onClick={handleItemClick}
            >
              <MenuItem onClick={handleItemClick}>Sub-Sub-Button 1</MenuItem>
              <MenuItem onClick={handleItemClick}>Sub-Sub-Button 2</MenuItem>
            </NestedMenuItem> */}
        </NestedMenuItem>
        {/* <MenuItem onClick={handleItemClick}>Button 4</MenuItem>
          <NestedMenuItem
            label="Button 5"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
            <MenuItem onClick={handleItemClick}>Sub-Button 1</MenuItem>
            <MenuItem onClick={handleItemClick}>Sub-Button 2</MenuItem>
          </NestedMenuItem> */}
      </Menu>

      <Menu
        open={!!marketPosition}
        onClose={() => setMarketPosition(null)}
        anchorEl={marketPosition}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <NestedMenuItem
          sx={{ minWidth: '150px', justifyContent: 'space-between' }}
          label="市場"
          parentMenuOpen={!!marketPosition}
          //   onClick={handleItemClick}
        >
          <MenuItem onClick={() => handleItemClick('/categoryboard')}>
            カテゴリー(TOP100)
          </MenuItem>
          <MenuItem onClick={() => handleItemClick('/ownproductboard')}>
            間接流通
          </MenuItem>
        </NestedMenuItem>
        {/* <MenuItem onClick={handleItemClick}>Button 2</MenuItem> */}
        <NestedMenuItem
          sx={{ minWidth: '150px', justifyContent: 'space-between' }}
          label="競合"
          parentMenuOpen={!!marketPosition}
          //   onClick={handleItemClick}
        >
          <MenuItem onClick={() => handleItemClick('/otherproductboard')}>
            商品分析
          </MenuItem>
          <MenuItem onClick={() => handleItemClick('/category1/sales')}>
            カテゴリーNo.1売上
          </MenuItem>
        </NestedMenuItem>
      </Menu>
    </div>
    // </div>
  );
};

export default NestedMenu;
// export const NestedMenu = () => {
//   const [menuPosition, setMenuPosition] = useState(null);

//   const handleRightClick = (event) => {
//     if (menuPosition) {
//       return;
//     }
//     event.preventDefault();
//     setMenuPosition({
//       top: event.pageY,
//       left: event.pageX
//     });
//   };

//   return (
//     <List>
//       {/* <Subheader>Nested List Items</Subheader> */}
//       {/* <ListItem primaryText="Sent mail" leftIcon={<ContentSend />} />
//       <ListItem primaryText="Drafts" leftIcon={<ContentSend />} /> */}
//       <ListItem primaryText="Sent mail" />
//       <ListItem primaryText="Drafts" />
//       <ListItem
//         primaryText="Inbox"
//         // leftIcon={<ContentSend />}
//         initiallyOpen={true}
//         primaryTogglesNestedList={true}
//         nestedItems={[
//           // <ListItem key={1} primaryText="Starred" leftIcon={<ContentSend />} />,
//           <ListItem key={1} primaryText="Starred" />,

//           <ListItem
//             key={2}
//             primaryText="Sent Mail"
//             // leftIcon={<ContentSend />}
//             disabled={true}
//             nestedItems={[
//               <ListItem
//                 key={1}
//                 primaryText="Drafts"
//                 // leftIcon={<ContentSend />}
//               />
//             ]}
//           />,
//           <ListItem
//             key={3}
//             primaryText="Inbox"
//             // leftIcon={<ContentSend />}
//             // open={this.state.open}
//             open={menuPosition}
//             onNestedListToggle={handleRightClick}
//             nestedItems={[
//               <ListItem
//                 key={1}
//                 primaryText="Drafts"
//                 // leftIcon={<ContentSend />}
//               />
//             ]}
//           />
//         ]}
//       />
//     </List>
//   );
// };
// export default NestedMenu;

/* eslint-disable*/
import {
  Link,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userinfo, userFirstLogin } from 'feactures/user/userSlice';
import ConnectionStatus2 from 'pages/ConnectionStatus2';
import AutoJudgeModal from 'pages/AutoJudgeModal';
import JudgeFailedModal from 'pages/JudgeFailedModal';
import { useState } from 'react';

const steps = ['アカウント作成', '審査', 'データ接続', 'Oxcim利用可能'];

//accountStatus
// 0：審査前
// 1：旧フロー審査完了
// 2：新フロー審査完了
// 99：競・反社

const ConnectionDialog = ({ dataConnectedStatus }) => {
  const user = useSelector(userinfo);
  const firstLogin = useSelector(userFirstLogin);
  // const firstLogin = true;

  return (
    <Dialog
      open={
        user.accountStatus == 0
        // || (modalDisplay == 1 && !dataConnectedStatus)
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="700px"
      scroll="body"
    >
      <DialogContent>
        {firstLogin ? (
          <AutoJudgeModal />
        ) : user.accountStatus == 0 || user.accountStatus == -99 ? (
          <JudgeFailedModal />
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ConnectionDialog;

/* eslint-disable*/
//ヘッダー
import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation, useNavigate } from 'react-router-dom';
import { userinfo, fetchLogout } from 'feactures/user/userSlice';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Popover,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Badge,
  Divider,
  colors
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import PaymentIcon from '@mui/icons-material/Payment';
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from '@mui/icons-material/Cached';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Notifications from '@material-ui/icons/Notifications';
import { Circle } from '@material-ui/icons';
import Logo from '../../../Logo';
import MenuNavbar from 'components/organisms/layout/dashboard/MenuNavbar';
import MenuNavbar2 from 'components/organisms/layout/dashboard/MenuNavbar2';
import NestedMenu from 'components/organisms/layout/dashboard/NestedMenu';
import PlanConfirmModal from 'components/pages/market/PlanConfirmModal';
import PlanChangeModal from 'components/pages/market/PlanChangeModal';
import { openConfirmModal, openChangeModal } from 'feactures/plan/planSlice';
import {
  allNews,
  fetchNews,
  allReadedNews,
  readedNews
} from 'feactures/user/newsSlice';
import { currentPage, currentPageFlag } from 'feactures/date/dateSlice';
// リロードに必要
import { ReloadLayoutContext } from 'components/templates/dashboard/DashboardLayout';

const DashboardNavbar = ({ onMobileNavOpen }) => {
  const { reloadStatus, setReloadStatus } = useContext(ReloadLayoutContext);
  const user = useSelector(userinfo);
  const allNewsInfo = useSelector(allNews);
  const nonReaded = allNewsInfo.filter((item) => item['readedStatus'] === 0); //未読のnewsList
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuId = 'primary-search-account-menu';
  const paymentMenuId = 'primary-search-payment-menu';
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifiAnchorEl, setNotifiAnchorEl] = useState(null);
  const [paymentAnchorEl, setPaymentAnchorEl] = useState(null);
  const [planAnchorEl, setPlanAnchorEl] = useState(null);
  const [selectedView, setSelectedView] = useState('');
  const pageFlag = useSelector(currentPageFlag);
  const location = useLocation();
  const path = location.pathname;
  const isMenuOpen = Boolean(anchorEl);
  const notificationOpen = Boolean(notifiAnchorEl);
  const isPaymentMenuOpen = Boolean(paymentAnchorEl);
  const planOpen = Boolean(planAnchorEl);
  const planName = { 0: 'Free', 1: 'Basic', 2: 'Ope', 3: 'Partner' };

  useEffect(() => {
    if (
      path === '/app/monitoring' ||
      path === '/app/group' ||
      path === '/app/monitoring/asin' ||
      path === '/app/numberviews' ||
      path === '/app/sales' ||
      path === '/app/trends' ||
      path === '/app/advertise' ||
      path === '/app/cvr' ||
      path === '/app/unitprice' ||
      path === '/app/asin' ||
      path === '/app/keywords/detail'
    ) {
      setSelectedView('KPIビュー');
    } else if (
      path === '/app/ownproductboard' ||
      path === '/app/otherproductboard' ||
      path === '/app/categoryboard' ||
      path === '/app/category1/sales' ||
      path === '/app/newcategoryboard' ||
      path === '/app/brandanalysis' ||
      path === '/app/brandcomparison' ||
      path === '/app/measureanalysis' ||
      path == '/app/performancereview'
    ) {
      setSelectedView('マーケットビュー');
    } else {
      setSelectedView('');
    }
  }, [path]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleChangePassword = () => {
    handleMenuClose();
    navigate('/app/sys/p/edit');
  };
  const handleConnectionStatus = () => {
    handleMenuClose();
    navigate('/app/sys/connection_status');
  };
  const handleRms = () => {
    handleMenuClose();
    navigate('/app/sys/rms');
  };
  const handleYahoo = () => {
    handleMenuClose();
    navigate('/app/sys/yahoo');
  };
  const handleUser = () => {
    handleMenuClose();
    navigate('/app/sys/user');
  };
  const handlePlan = () => {
    handleMenuClose();
    navigate('/app/sys/plan');
  };
  const handleBilling = () => {
    handleMenuClose();
    navigate('/app/sys/billing');
  };
  const handleAdvertise = () => {
    handleMenuClose();
    navigate('/app/sys/ad');
  };
  const handleLogout = async () => {
    handleMenuClose();
    await dispatch(fetchLogout())
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        setTimeout(function () {
          navigate('/login');
        }, 2000);
      });
    navigate('/login', { replace: false });
  };
  const handleNotificationMenuOpen = (event) => {
    dispatch(fetchNews());
    setNotifiAnchorEl(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setNotifiAnchorEl(null);
  };
  const handleClickNews = (news) => () => {
    // console.log(news.id);
    navigate(`/app/news/${news.id}`);
    handleNotificationClose();
    //未読の場合接続
    if (news.readedStatus === 0) {
      dispatch(readedNews({ newsId: news.id }));
    }
  };
  const handleAllReadedClick = () => {
    dispatch(allReadedNews());
  };

  const handlePlanOpen = (event) => {
    setPlanAnchorEl(event.currentTarget);
  };
  const handlePlanClose = () => {
    setPlanAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleUser}>ユーザー一覧</MenuItem> */}
      <MenuItem onClick={handlePlan}>プラン管理</MenuItem>
      <MenuItem onClick={handleBilling}>ご請求情報</MenuItem>
      {/* <MenuItem onClick={handleAdvertise}>広告連携設定</MenuItem> */}
      {user.accountStatus != 0 && (
        <MenuItem onClick={handleConnectionStatus}>データ接続状況確認</MenuItem>
      )}
      <MenuItem onClick={handleRms}>楽天API連携設定</MenuItem>
      <MenuItem onClick={handleYahoo} disabled={true}>
        Yahoo!API連携設定
      </MenuItem>
      <MenuItem onClick={handleChangePassword}>パスワード変更</MenuItem>
      <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
    </Menu>
  );

  const handlePaymentMenuOpen = (event) => {
    setPaymentAnchorEl(event.currentTarget);
  };

  const handlePaymentMenuClose = () => {
    setPaymentAnchorEl(null);
  };

  const handleChangePlan = () => {
    handlePaymentMenuClose();
    dispatch(openChangeModal());
  };

  const renderPaymentMenu = (
    <Menu
      id={paymentMenuId}
      anchorEl={paymentAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={handlePaymentMenuClose}
      open={isPaymentMenuOpen}
    >
      {/* <MenuItem onClick={confirmCurrentPlan}>現在のプランを確認する</MenuItem> */}
      <MenuItem onClick={handleChangePlan}>プランを変更する</MenuItem>
    </Menu>
  );

  const style = {
    // width: '100%',
    // // maxWidth: 450,
    width: 360,
    bgcolor: 'background.paper'
  };
  const notification = (
    <Popover
      open={notificationOpen}
      anchorEl={notifiAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={handleNotificationClose}
    >
      <Box p={1} pb={0} display="flex" alignItems={'center'}>
        <Typography fontWeight="bold" variant="h5">
          お知らせ
        </Typography>
        <Button variant="text" onClick={handleAllReadedClick}>
          全て既読
        </Button>
        <IconButton
          size="small"
          onClick={handleNotificationClose}
          sx={{ marginLeft: 'auto' }}
        >
          <ClearIcon color="action" size="3px" />
        </IconButton>
      </Box>
      <Divider />
      <Box maxHeight="calc(40vh - 10px)" overflow="auto">
        <List sx={style} component="nav" aria-label="mailbox folders">
          {allNewsInfo.length !== 0 ? (
            <>
              {allNewsInfo.map((news) => (
                <ListItem
                  sx={{ fontSize: '12px' }}
                  button
                  divider
                  key={news.id}
                  onClick={handleClickNews(news)}
                >
                  {!news.readedStatus && (
                    // <ListItemIcon>
                    <Circle
                      sx={{ color: colors.red[900], mx: 1 }}
                      fontSize="2px"
                    />
                    // </ListItemIcon>
                  )}
                  <ListItemText
                    sx={{
                      wordBreak: 'break-all',
                      maxHeight: 60
                    }}
                    primary={news.title}
                    secondary={news.startDate}
                  />
                </ListItem>
              ))}
            </>
          ) : (
            <ListItem>
              <ListItemText primary="新着のお知らせはありません" />
            </ListItem>
          )}
        </List>
      </Box>
    </Popover>
  );

  const handleReload = () => {
    // console.log('リロードボタン押下');
    setReloadStatus(true);
  };

  return (
    <>
      {/* <AppBar position="fixed" elevation={4} sx={{ color: '#F4F6F8' }}> */}
      <AppBar
        position="fixed"
        elevation={4}
        sx={{ backgroundColor: '#FFF8E5' }}
      >
        <Toolbar sx={{ backgroundColor: '#FFF8E5' }}>
          <Box display="flex" alignItems="end" width="220px">
            <RouterLink to="/" style={{ textDecoration: 'none' }}>
              {/* <Typography
              variant="h2"
              ml={3}
              sx={{ color: '#6B778C', fontFamily: 'Book Antiqua' }}
            >
              Oxcim
            </Typography> */}
              <Logo />
            </RouterLink>
            <Typography
              mt={1.5}
              ml={0.5}
              sx={{
                fontSize: '10px',
                color: '#6B778C',
                fontFamily: 'Book Antiqua'
              }}
            >
              Ⓡ
            </Typography>
          </Box>
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          {/* <Hidden xlDown> */}
          {/* {user.accountName ? ( */}
          <Box
            display="flex"
            justifyContent="space-between"
            width="calc(100% - 220px)"
          >
            <Typography
              fontSize="16px"
              fontWeight="bold"
              sx={{ color: '#6B778C' }}
            >
              {/* KPIビューorマーケットビュー */}
              {selectedView}
            </Typography>
            <Box display="flex" alignItems="center">
              {user.userId ? (
                <>
                  {pageFlag && (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#51ADED',
                        marginRight: '30px',
                        height: '20px'
                      }}
                      onClick={handleReload}
                    >
                      <CachedIcon
                        color="white"
                        size="3px"
                        padding-right="5px"
                      />
                      更新
                    </Button>
                  )}
                  {/* プラン */}
                  <Typography variant="h6" sx={{ color: '#6B778C' }}>
                    利用中のプラン：
                  </Typography>
                  <Button onClick={handlePlanOpen} sx={{ mr: 3 }}>
                    <Typography variant="h6" sx={{ color: '#6B778C' }}>
                      {planName[user.plan]}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ color: '#6B778C' }} />
                  </Button>
                  <Popover
                    open={planOpen}
                    anchorEl={planAnchorEl}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    onClose={handlePlanClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      px={2}
                      pt={2}
                      pb={1}
                    >
                      <Typography sx={{ pb: 1 }}>
                        有料プランをご利用いただくと～～～～
                        <br />
                        ○○○○○○○○○できます。
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => {
                          handlePlanClose();
                          navigate('/app/sys/plan');
                        }}
                      >
                        詳しくはこちら
                      </Button>
                    </Box>
                  </Popover>
                  <Typography variant="h6" mr={3} sx={{ color: '#6B778C' }}>
                    {/* アカウント名 */}
                    {user.accountName}
                  </Typography>
                  <Typography variant="h6" mr={3} sx={{ color: '#6B778C' }}>
                    {/* ユーザー名 */}
                    {user.userName}　様
                  </Typography>
                  {/* <IconButton
                    size="small"
                    aria-label="manage plan"
                    aria-haspopup="true"
                    onClick={handlePaymentMenuOpen}
                    aria-controls={paymentMenuId}
                  >
                    <PaymentIcon />
                  </IconButton> */}
                  <IconButton
                    size="small"
                    // edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                  >
                    <AccountCircle />
                  </IconButton>
                  <IconButton
                    size="small"
                    aria-label="show new notifications"
                    onClick={handleNotificationMenuOpen}
                  >
                    <Badge badgeContent={nonReaded.length} color="error">
                      <Notifications />
                    </Badge>
                  </IconButton>
                  {/* <Hidden lgUp>
                <IconButton
                  color="inherit"
                  onClick={onMobileNavOpen}
                  size="large"
                >
                  <MenuIcon sx={{ color: '#6B778C' }} />
                </IconButton>
              </Hidden> */}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </Toolbar>
        <Divider />
        {/* {user.accountStatus != 0 && <NestedMenu />} */}
      </AppBar>
      {/* {renderPaymentMenu} */}
      {renderMenu}
      {notification}
      {/* <PlanConfirmModal />
      <PlanChangeModal /> */}
    </>
  );
};
export default DashboardNavbar;

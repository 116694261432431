/* eslint-disable */
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'components/templates/dashboard/DashboardLayout';
import MainLayout from 'components/templates/main/MainLayout';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import ReregisterPass from 'pages/ReregisterPass';
import ChangePassword from 'pages/ChangePassword';
import UserList from 'pages/UserList';
import AddNewUser from 'pages/AddNewUser';
import SettingAdvertise from 'pages/SettingAdvertise.js';
import ConnectionStatus from 'pages/ConnectionStatus';
import SettingRms from 'pages/SettingRms';
import SettingYahoo from 'pages/SettingYahoo';
import Settings from 'pages/Settings';
import KpiSummaryBoard from 'components/pages/kpi_view/KpiSummaryBoard';
import News from 'pages/News';
import RegisterComplete from 'pages/RegisterComplete';
import MasterLogin from 'pages/MasterLogin';
import ChannelStatusDialog from 'components/atoms/modal/ChannelStatusDialog';

//market
import ProductAnalysis from 'components/pages/market/ProductAnalysis';
import NewCategoryboard from 'components/pages/market/NewCategoryboard';

//kpi
import Example from 'components/pages/UploadRakutenCSV';
import TermsOfService from 'components/pages/TermsOfService';
import BrandAnalysis from 'components/pages/market/BrandAnalysis';
import NewMeasureAnalysis from 'components/pages/market/NewMeasureAnalysis';
import BrandComparison from 'components/pages/market/BrandComparison';
import UpdatePassword from 'pages/UpdatePassword';
import SettingPlan from 'pages/SettingPlan';
import CheckoutComplete from 'pages/CheckoutComplete';
import TotalSales from 'components/pages/overview/TotalSales';
import PremiumRegister from 'pages/PremiumRegister';
import PremiumRegisterComplete from 'pages/PremiumRegisterComplete';
import Billing from 'pages/Billing';
// import TrialRegister from 'pages/TrialRegister';
// import TrialRegisterComplete from 'pages/TrialRegisterComplete';
// import TrialCampaignRegisterComplete from 'pages/TrialCampaignRegisterComplete';
// import NewTrialPremiumRegisterComplete from 'pages/NewTrialRegisterComplete';
// import TrialPremiumRegister from 'pages/TrialPremiumRegister';
import StepRegister from 'pages/StepRegister';
import StepRegisterNewPremium from 'pages/StepRegisterNewPremium';
// import NewTrialPremiumRegister from 'pages/NewTrialPremiumRegister';
// import MeasuresTrialRegister from 'pages/MeasuresTrialRegister';
// import MeasuresTrialRegisterComplete from 'pages/MeasuresTrialRegisterComplete';
import PerformanceReview from 'components/pages/market/PerformanceReview';
import StepRegisterNewPremiumComplete from 'pages/StepRegisterNewPremiumComplete';
import StepRegisterNewConsultingPremium from 'pages/StepRegisterNewConsultingPremium';

//stateのloginがfalseのときはlogin画面に遷移
const routes = (
  isLoggedIn,
  groupLength,
  channel,
  premiumPlan,
  selectedBrandStatus,
  selectedCategoryStatus,
  freePlanRegisterInfosSuccessFlag,
  updateFreePlanRegisterInfosSuccessFlag,
  isDataConnected
) => [
  // ログイン情報関係なく利用規約はみせる
  {
    path: 's',
    element: isLoggedIn ? <DashboardLayout /> : <MainLayout />,
    children: [{ path: 'tos', element: <TermsOfService /> }]
  },
  {
    path: 'checkout',
    element: <CheckoutComplete />
  },
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      //overview
      {
        path: 'overview',
        element: isDataConnected ? (
          <TotalSales />
        ) : (
          <ChannelStatusDialog pageName="オーバービュー" />
        )
      },
      {
        path: 'monitoring',
        element: isDataConnected ? (
          <KpiSummaryBoard />
        ) : (
          <ChannelStatusDialog pageName="KPIビュー" />
        ),
        children: [
          groupLength > 0 && { path: '/group', element: <KpiSummaryBoard /> },
          { path: '/asin', element: <KpiSummaryBoard /> }
        ]
      },
      {
        path: 'otherproductboard',
        element: isDataConnected ? (
          <ProductAnalysis />
        ) : (
          <ChannelStatusDialog pageName="商品分析" />
        )
      },
      {
        path: 'newcategoryboard',
        element:
          // 有料でなく、カテゴリとブランドが未登録の場合にfreePlanRegisterModalOpen=Trueフラグを渡す
          isDataConnected ? (
            <NewCategoryboard
              freePlanRegisterModalOpen={
                !premiumPlan &&
                (!selectedBrandStatus || !selectedCategoryStatus) &&
                !freePlanRegisterInfosSuccessFlag[channel]
              }
              updateFreePlanRegisterInfosSuccessFlag={
                updateFreePlanRegisterInfosSuccessFlag
              }
            />
          ) : (
            <ChannelStatusDialog pageName="カテゴリ分析" />
          )
      },
      {
        path: 'brandanalysis',
        element: isDataConnected ? (
          <BrandAnalysis
            freePlanRegisterModalOpen={
              !premiumPlan &&
              (!selectedBrandStatus || !selectedCategoryStatus) &&
              !freePlanRegisterInfosSuccessFlag[channel]
            }
            updateFreePlanRegisterInfosSuccessFlag={
              updateFreePlanRegisterInfosSuccessFlag
            }
          />
        ) : (
          <ChannelStatusDialog pageName="店舗/ブランド分析" />
        )
      },
      {
        path: 'brandcomparison',
        element: isDataConnected ? (
          <BrandComparison
            freePlanRegisterModalOpen={
              !premiumPlan &&
              (!selectedBrandStatus || !selectedCategoryStatus) &&
              !freePlanRegisterInfosSuccessFlag[channel]
            }
            updateFreePlanRegisterInfosSuccessFlag={
              updateFreePlanRegisterInfosSuccessFlag
            }
          />
        ) : (
          <ChannelStatusDialog pageName="自社比較分析" />
        )
      },
      {
        path: 'measureanalysis',
        element: <NewMeasureAnalysis />
      },
      {
        path: 'performancereview',
        element: <PerformanceReview />
      },

      //etc
      { path: 'news/:id', element: <News /> },
      { path: 'sys/p/edit', element: <ChangePassword /> },
      { path: 'sys/user', element: <UserList /> },
      { path: 'sys/user/new', element: <AddNewUser /> },
      { path: 'sys/connection_status', element: <ConnectionStatus /> },
      { path: 'sys/rms', element: <SettingRms /> },
      { path: 'sys/yahoo', element: <SettingYahoo /> },
      { path: 'sys/ad', element: <SettingAdvertise /> },
      { path: 'sys/plan', element: <SettingPlan /> },
      { path: 'sys/billing', element: <Billing /> },
      { path: 'tos', element: <TermsOfService /> },
      { path: 'example', element: <Example /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },

  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'master/login', element: <MasterLogin /> },
      { path: 's/tos', element: <TermsOfService /> },
      { path: 'register', element: <StepRegister /> },
      { path: 'register/complete', element: <RegisterComplete /> },
      { path: 'register_new_premium', element: <StepRegisterNewPremium /> },
      {
        path: 'register_new_premium/complete',
        element: <StepRegisterNewPremiumComplete />
      },
      {
        path: 'register_new_consulting_premium',
        element: <StepRegisterNewConsultingPremium />
      },
      { path: 'premium_register', element: <PremiumRegister /> },
      // {
      //   path: 'trial_premium_register/prev',
      //   element: <TrialPremiumRegister />
      // },
      // {
      //   path: 'new_trial_premium_register',
      //   element: <NewTrialPremiumRegister />
      // },
      {
        path: 'premiumregister/complete',
        element: <PremiumRegisterComplete />
      },
      // {
      //   path: 'trial_campaign_register/complete',
      //   element: <NewTrialPremiumRegisterComplete />
      // },
      // {
      //   path: 'trial_campaign_register/prev/complete',
      //   element: <TrialCampaignRegisterComplete />
      // },
      // { path: 'trial_register/prev', element: <TrialRegister /> },
      // {
      //   path: 'trialregister/complete',
      //   element: <TrialRegisterComplete />
      // },
      // { path: 'measures_trial_register', element: <MeasuresTrialRegister /> },
      // {
      //   path: 'measurestrialregister/complete',
      //   element: <MeasuresTrialRegisterComplete />
      // },
      { path: 'reregister', element: <ReregisterPass /> },
      { path: 'updatepass', element: <UpdatePassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/monitoring" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

/* eslint-disable*/
import {
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  Paper
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectOtherproducts,
  fetchAsyncOtherproducts,
  otherproductsStatus
} from 'feactures/product/otherproductSlice';
import NewButtongroup from 'components/atoms/group/NewButtongroup';
import NewDialogSelect from 'components/atoms/select/NewDialogSelect';
import NewPullDown from 'components/atoms/button/NewPullDown';
import FavoriteCategoryPullDown from 'components/atoms/button/FavoriteCategoryPullDown';
import { registerOther } from 'feactures/section/sectionSlice';
import SimpleModal from 'components/atoms/modal/SimpleModal';
import ProductAnalysisTable from 'components/organisms/content/product/analysis/ProductAnalysisTable';
import { changeKubun } from 'feactures/section/sectionSlice';
import { currentchannel, registerAmazon } from 'feactures/channel/channelSlice';
import StickyAnnotation from 'components/atoms/annotation/StickyAnnotation';
import {
  fetchAsinList,
  setDisplayObject
} from 'feactures/monitoring/monitorAsinSlice';
import {
  amazonCategory,
  rakutenCategory,
  yahooCategory
} from 'feactures/category/categorySlice';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { changePage } from 'feactures/date/dateSlice';

const ProductAnalysis = () => {
  const dispatch = useDispatch();
  const productList = useSelector(selectOtherproducts);
  const loading = useSelector(otherproductsStatus);
  const channel = useSelector(currentchannel);
  const amazonCategories = useSelector(amazonCategory);
  const rakutenCategories = useSelector(rakutenCategory);
  const yahooCategories = useSelector(yahooCategory);

  const [yokoziku, setYokoziku] = useState([]);

  let categories = '';

  if (channel == 1) {
    categories = amazonCategories;
  } else if (channel == 2) {
    categories = rakutenCategories;
  } else if (channel == 3) {
    categories = yahooCategories;
  }

  // 戻るボタン押されたとき用
  useEffect(() => {
    dispatch(changePage('otherProductBoard'));
  }, []);

  useEffect(() => {
    if (channel == 0) {
      // チャンネルをamazonに変える
      dispatch(registerAmazon());
      // チャンネルが変わるのでKPIサマリで使うAsinListを再取得
      dispatch(fetchAsinList());
      dispatch(setDisplayObject('top10'));
    }

    dispatch(registerOther());
    dispatch(changeKubun('direct'));
  }, [dispatch]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          pb: '80px'
        }}
      >
        <Container maxWidth={false} sx={{ width: 'auto', p: 0 }}>
          <Box
            position="sticky"
            top="42px"
            bgcolor="#FFF8E5"
            zIndex={3}
            height="auto"
          >
            {/* {channel == 2 && <StickyAnnotation />} */}
            <Box sx={{ display: 'flex', alignItems: 'center', height: '33px' }}>
              <Typography fontSize="14px">マーケットビュー</Typography>
              <ArrowForwardIosOutlinedIcon
                sx={{ fontSize: '16px', mx: 1, color: 'gray' }}
              />
              <Typography fontSize="14px" fontWeight="bold">
                商品分析
              </Typography>
            </Box>

            <Box
              mb={2}
              sx={{
                display: 'flex',
                borderTop: 1,
                borderColor: '#FFFFFF',
                alignItems: 'flex-end'
              }}
            >
              <Box mr={3}>
                <Typography fontSize="14px">&nbsp;</Typography>
                <NewButtongroup amazonOnly={true} page="otherProduct" />
              </Box>
              <Box mr={3}>
                {/* <Typography fontSize="14px">期間</Typography> */}
                <NewPullDown
                  page="otherProduct"
                  label="期間"
                  setYokoziku={setYokoziku}
                />
              </Box>
              {/* <Box mr={3}>
                <Typography fontSize="14px">カテゴリを選択する</Typography>
                <NewDialogSelect
                  categories={categories}
                  channel={channel}
                  // disabledにするためにpageを一時的にproductMeasuresに変更
                  page="productMeasures"
                />
              </Box>
              <Box>
                <Typography fontSize="14px">お気に入りカテゴリ</Typography>
                <FavoriteCategoryPullDown
                  // disabledにするためにpageを一時的にproductMeasuresに変更
                  page="productMeasures"
                />
              </Box> */}
            </Box>
          </Box>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box container width="full" bgcolor="#FFFFFF" borderRadius={3}>
                <SimpleModal />
                {channel == 1 ? (
                  <ProductAnalysisTable
                    yokoziku={yokoziku}
                    productList={
                      productList[0].length !== 0 ? productList[0] : []
                    }
                    channel={channel}
                  />
                ) : null}
                {channel == 2 ? (
                  <ProductAnalysisTable
                    yokoziku={yokoziku}
                    productList={
                      productList[1].length !== 0 ? productList[1] : []
                    }
                    channel={channel}
                  />
                ) : null}
                {channel == 3 ? (
                  <ProductAnalysisTable
                    yokoziku={yokoziku}
                    productList={
                      productList[2].length !== 0 ? productList[2] : []
                    }
                    channel={channel}
                  />
                ) : null}
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};
export default ProductAnalysis;

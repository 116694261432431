/* eslint-disable*/
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
  List
} from '@material-ui/core';
import PerformancePullDown from 'components/atoms/button/PerformancePullDown';
import {
  currentchannel,
  currentchannelName
} from 'feactures/channel/channelSlice';
import {
  fetchKeywordGraph,
  fetchKeywordList,
  performanceModalOpen,
  performancereviewAmazonOtherItemData,
  performancereviewAmazonOwnItemData,
  performancereviewOtherDataLoading,
  performancereviewOwnDataLoading,
  performancereviewRakutenOtherItemData,
  performancereviewRakutenOwnItemData,
  performanceSeokeywordList,
  performanceSeoLoading,
  performanceSeoSetting,
  resetSeoData,
  resetKeyword,
  setCompareType,
  setKeyword,
  setPerformanceModalOpen,
  setWordType,
  fetchRegisterSeoKeyword,
  fetchDeleteSeoKeyword
} from 'feactures/performancereview/performancereviewSlice';
import FreePlanUpgradeDialog from 'components/atoms/modal/FreePlanUpgradeDialog';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SeoCompareLineGraph from './SeoCompareLineGraph';
import { errorResMessage, setError } from 'feactures/errorSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { userinfo } from 'feactures/user/userSlice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const SeoResultDialog = (props) => {
  const { index } = props;
  const dispatch = useDispatch();
  const modalOpen = useSelector(performanceModalOpen);
  const [inputWord, setInputWord] = useState('');
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const setting = useSelector(performanceSeoSetting);
  const ownLoading = useSelector(performancereviewOwnDataLoading);
  const otherLoading = useSelector(performancereviewOtherDataLoading);
  const seoLoading = useSelector(performanceSeoLoading);
  const keywordList = useSelector(performanceSeokeywordList);
  const ownAmazonData = useSelector(performancereviewAmazonOwnItemData);
  const ownRakutenData = useSelector(performancereviewRakutenOwnItemData);
  const otherAmazonData = useSelector(performancereviewAmazonOtherItemData);
  const otherRakutenData = useSelector(performancereviewRakutenOtherItemData);
  const errorMessage = useSelector(errorResMessage);
  const channel = useSelector(currentchannel);
  const channelName = useSelector(currentchannelName);
  const userInfo = useSelector(userinfo);
  let ownData = [];
  let otherData = [];
  if (channel == 1) {
    ownData = ownAmazonData;
    otherData = otherAmazonData;
  } else if (channel == 2) {
    ownData = ownRakutenData;
    otherData = otherRakutenData;
  }

  const handleChangeWord = (e) => {
    e.preventDefault();
    const word = e.target.value
      .replace(/[\s、]+/g, ',') // スペースや改行、「、」をカンマに置換
      .replace(/,+/g, ','); // 連続するカンマを1つに置換
    setInputWord(word);
  };
  const handleRegister = async () => {
    if (!userInfo.plan[channelName].premium && keywordList.length >= 1) {
      setOpenUpgradeDialog(true);
    } else {
      dispatch(setError(''));
      const wordArray = String(inputWord).split(',');
      await dispatch(fetchRegisterSeoKeyword({ keyword: wordArray }))
        .then(unwrapResult) //registerProductsで登録が成功（fullfilled）のときはモーダルを閉じる
        .then(() => {
          dispatch(fetchKeywordList());
          setInputWord('');
          window.confirm('キーワード登録が完了しました');
        })
        .catch((err) => {
          setInputWord(inputWord);
        });
    }
  };

  const handleUpgradeDialogClose = () => {
    setOpenUpgradeDialog(false);
  };

  const deleteKeyword = async () => {
    if (
      window.confirm(
        //eslint-disable-line
        'キーワードを削除しますか？※削除されたキーワードのデータは今後蓄積されません'
      )
    ) {
      await dispatch(fetchDeleteSeoKeyword())
        .then(unwrapResult) //registerProducts削除が成功（fullfilled）のときはモーダルを閉じる
        .then(() => {
          dispatch(fetchKeywordList());
          dispatch(resetKeyword());
          window.confirm('キーワード削除が完了しました');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('cancel');
    }
  };
  return (
    <>
      <Button
        color="groupSelected"
        variant="contained"
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          dispatch(fetchKeywordList());
          dispatch(setPerformanceModalOpen({ index, open: true })); // モーダルを開く
        }}
        size="small"
        sx={{ minWidth: '50px' }}
      >
        詳細
      </Button>
      <Dialog
        open={modalOpen.open && index == modalOpen.index}
        onClose={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
          dispatch(setPerformanceModalOpen({ index: 0, open: false })); // モーダルを閉じる
        }}
        onClick={(event) => {
          event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
        }}
        PaperProps={{
          sx: { width: '90%', maxWidth: '1500px' }
        }}
      >
        <Box p={3}>
          <Box height="70%">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                color="rgba(113,140,198,1)"
                fontWeight="bold"
                fontSize="15px"
              >
                検索順位モニタリング
              </Typography>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
                  dispatch(resetKeyword());
                  dispatch(setPerformanceModalOpen({ index: 0, open: false })); // モーダルを閉じる
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center" mt={2}>
              <Box>
                <Typography>表示月</Typography>
                <PerformancePullDown
                  graphLoading={ownLoading || otherLoading || seoLoading}
                  page="search"
                />
              </Box>
              <Box ml={1}>
                <Typography>比較タイプ</Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <Button
                    size="small"
                    color="groupSelected"
                    variant={
                      setting.compareType == 'own' ? 'contained' : 'outlined'
                    }
                    onClick={() => {
                      dispatch(resetKeyword());
                      dispatch(setCompareType('own'));
                      dispatch(fetchKeywordList());
                    }}
                    disabled={ownData.itemId == ''}
                  >
                    自社検索順位
                  </Button>
                  <Button
                    size="small"
                    color="groupSelected"
                    variant={
                      setting.compareType == 'other' ? 'contained' : 'outlined'
                    }
                    onClick={() => {
                      dispatch(resetKeyword());
                      dispatch(setCompareType('other'));
                      dispatch(fetchKeywordList());
                    }}
                    disabled={otherData.itemId == ''}
                  >
                    競合検索順位
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box mt={1}>
              <Typography>キーワードタイプ</Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <Button
                  size="small"
                  color="groupSelected"
                  variant={
                    setting.keywordType == 'specific' ? 'contained' : 'outlined'
                  }
                  sx={{ minWidth: 'calc(50% - 4px)' }}
                  onClick={() => {
                    dispatch(resetKeyword());
                    dispatch(setWordType('specific'));
                    dispatch(fetchKeywordList());
                  }}
                >
                  指名
                </Button>
                <Button
                  size="small"
                  color="groupSelected"
                  variant={
                    setting.keywordType == 'general' ? 'contained' : 'outlined'
                  }
                  sx={{ minWidth: 'calc(50% - 4px)' }}
                  onClick={() => {
                    dispatch(resetKeyword());
                    dispatch(setWordType('general'));
                    dispatch(fetchKeywordList());
                  }}
                >
                  一般
                </Button>
              </Box>
            </Box>
            <Box mt={1}>
              <Typography>
                新しいキーワード（カンマ区切りで複数登録可能）
              </Typography>
              <Typography color="error" fontWeight={600}>
                ※キーワードは{userInfo.plan[channelName].premium ? '5' : '1'}
                つまで登録が可能です。
              </Typography>
              <form
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                onSubmit={(e) => {
                  e.preventDefault(); // ページリロードを防ぐ
                  handleRegister();
                }}
              >
                <TextField
                  placeholder="新しいキーワードを追加"
                  value={inputWord}
                  onChange={(e) => {
                    handleChangeWord(e);
                  }}
                  inputProps={{
                    style: { fontSize: '13px' }
                  }}
                  size="small"
                  sx={{
                    flexGrow: 1, // TextFieldを最大幅にする
                    mr: '4px' // ボタンとの間に余白を確保
                  }}
                />
                <Button
                  type="submit"
                  sx={{ minWidth: '50px', ml: 1 }}
                  size="small"
                  color="groupSelected"
                  variant="contained"
                  disabled={ownLoading || otherLoading || seoLoading}
                >
                  追加
                </Button>
              </form>
              {errorMessage && (
                <Typography color="red">{errorMessage}</Typography>
              )}
            </Box>
            <Typography fontWeight={600} my={1}>
              掲載順位は登録した翌日から反映されます
            </Typography>
            <Box
              display="flex"
              minWidth="890px"
              minHeight="450px"
              border={1}
              borderColor="#C3C3C3"
              borderRadius={1}
              justifyContent={
                ownLoading || otherLoading || seoLoading ? 'center' : 'start'
              }
            >
              {ownLoading || otherLoading || seoLoading ? (
                <Box display="flex" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Box width="20%" borderRight={1} borderColor="#C3C3C3">
                    <Typography fontWeight="bold" fontSize="14px">
                      キーワード一覧
                    </Typography>
                    {keywordList.length == 0 ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography>キーワードがありません</Typography>
                      </Box>
                    ) : (
                      <List
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 300,
                          '& ul': { padding: 0 }
                        }}
                        subheader={<li />}
                      >
                        {keywordList.map((value, index) => {
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton
                                role={undefined}
                                onClick={() => {
                                  dispatch(setKeyword(value));
                                  dispatch(fetchKeywordGraph());
                                }}
                                dense
                                sx={{
                                  backgroundColor:
                                    value == setting.keyword
                                      ? '#DBEAFE'
                                      : '#FFFFFF' // 背景色
                                }}
                              >
                                <ListItemText id={index} primary={value} />
                                {value == setting.keyword && (
                                  <IconButton
                                    sx={{ p: 0 }}
                                    onClick={(event) => {
                                      event.stopPropagation(); // 親要素のテーブルの開閉が起きないようイベントの伝播を停止
                                      deleteKeyword();
                                    }}
                                  >
                                    <DeleteForeverIcon />
                                  </IconButton>
                                )}
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    )}
                  </Box>
                  <Box width="80%" minHeight="300px">
                    {setting.keyword == '' ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Typography color="text.secondary">
                          キーワードを選択してください
                        </Typography>
                      </Box>
                    ) : (
                      <SeoCompareLineGraph />
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
      {/* 無料プランアップグレードダイアログ */}
      <FreePlanUpgradeDialog
        open={openUpgradeDialog}
        onClose={handleUpgradeDialogClose}
        word={'キーワード'}
        page={'seoResultDialog'}
      />
    </>
  );
};

export default SeoResultDialog;

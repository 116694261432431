/* eslint-disable*/

import { Container, Typography, Box } from '@material-ui/core'; // eslint-disable-line
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { otherSelectedBrand } from 'feactures/brand/brandSlice';
import ProductAnalysisList from './ProductAnalysisList';
import { currentchannel } from 'feactures/channel/channelSlice';

const ProductAnalysisTable = (props) => {
  const { yokoziku, productList, channel } = props;
  // const selectBrand = useSelector(otherSelectedBrand);

  return (
    <Container maxWidth={false} sx={{ mt: '-30px', mb: '30px' }}>
      <Box display="flex" alignItems="start" mb={'10px'} flexDirection="column">
        <Typography fontSize="14px" fontWeight="bold">
          商品分析
        </Typography>
        {channel == 1 && (
          <Typography fontSize="12px">
            Amazonはカテゴリランキング100位以上の商品が表示可能です。<br />
            バリエーション商品はカテゴリが異なる場合、登録時に他のバリエーションも表示されます。
          </Typography>
        )}
        {channel == 2 && (
          <Typography fontSize="12px">
            楽天はカテゴリランキング300位以上の商品が表示可能です。
          </Typography>
        )}
      </Box>

      <ProductAnalysisList
        productList={productList.length !== 0 ? productList : []}
        yokoziku={yokoziku}
        section="競合"
      />
    </Container>
  );
};

export default ProductAnalysisTable;

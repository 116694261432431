/* eslint-disable*/
import { Box, Container, Typography } from '@material-ui/core';
import ImprovementSuggestions from 'components/organisms/content/performance/ImprovementSuggestions';
import ReviewResult from 'components/organisms/content/performance/ReviewResult';
import TotalScore from 'components/organisms/content/performance/TotalScore';
import { currentchannel } from 'feactures/channel/channelSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import StickyAnnotation from 'components/atoms/annotation/StickyAnnotation';

const PerformanceReview = () => {
  const channel = useSelector(currentchannel);
  return (
    <Box
      sx={{
        backgroundColor: '#FFF8E5',
        height: 'auto',
        minHeight: '100vh',
        width: '100%',
        pb: '80px'
      }}
    >
      <Container maxWidth={false} sx={{ width: '98%', p: 0 }}>
        <Box
          position="sticky"
          top="42px"
          bgcolor="#FFF8E5"
          zIndex={3}
          pt="2px"
          height="auto"
        >
          {/* {channel == 2 && <StickyAnnotation />} */}
          <Box display="flex">
            <Typography fontSize="16px" sx={{ py: '4px' }}>
              マーケットビュー＞
            </Typography>
            <Typography fontSize="16px" fontWeight="bold" sx={{ py: '4px' }}>
              商品販売力診断β版
            </Typography>
          </Box>
          <TotalScore />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <ReviewResult />
            <ImprovementSuggestions />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PerformanceReview;

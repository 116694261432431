/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchBrandShareGraphData = createAsyncThunk(
  'category/brandshare/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    // 最後に非ゼロのカテゴリを見つけ、その位置を階層として計算
    const findLastLevel = (category) => {
      // 0 や null ではない、最後の有効なカテゴリを見つける
      const nonZeroCategory = category.filter(
        (item) => item !== 0 && item !== null && item !== undefined
      );
      return nonZeroCategory.length; // 有効なカテゴリの数を階層にする
    };

    // それぞれのカテゴリの階層を計算
    const amazonLevel = findLastLevel([
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6
    ]);
    const rakutenLevel = findLastLevel([
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5
    ]);
    const yahooLevel = findLastLevel([y_cate1, y_cate2, y_cate3, y_cate4]);

    // channel に応じてどのカテゴリの level を渡すか決定
    let categoryLevel = 0;
    if (channel === 1) {
      categoryLevel = amazonLevel;
    } else if (channel === 2) {
      categoryLevel = rakutenLevel;
    } else if (channel === 3) {
      categoryLevel = yahooLevel;
    }
    const res = await client.post(`${apiUrl}category/brand_sales_share`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel,
      category_level: categoryLevel
    });
    return res.data;
  }
);

export const fetchBrandShareListData = createAsyncThunk(
  'category/brandshare_list/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    // 最後に非ゼロのカテゴリを見つけ、その位置を階層として計算
    const findLastLevel = (category) => {
      // 0 や null ではない、最後の有効なカテゴリを見つける
      const nonZeroCategory = category.filter(
        (item) => item !== 0 && item !== null && item !== undefined
      );
      return nonZeroCategory.length; // 有効なカテゴリの数を階層にする
    };

    // それぞれのカテゴリの階層を計算
    const amazonLevel = findLastLevel([
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6
    ]);
    const rakutenLevel = findLastLevel([
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5
    ]);
    const yahooLevel = findLastLevel([y_cate1, y_cate2, y_cate3, y_cate4]);

    // channel に応じてどのカテゴリの level を渡すか決定
    let categoryLevel = 0;
    if (channel === 1) {
      categoryLevel = amazonLevel;
    } else if (channel === 2) {
      categoryLevel = rakutenLevel;
    } else if (channel === 3) {
      categoryLevel = yahooLevel;
    }
    const res = await client.post(`${apiUrl}category/brand_sales_share_list`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel,
      category_level: categoryLevel
    });
    return res.data;
  }
);

export const fetchBrandItemShareData = createAsyncThunk(
  'category/brand_item_share/post',
  async (arg, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { brand } = arg;
    // 最後に非ゼロのカテゴリを見つけ、その位置を階層として計算
    const findLastLevel = (category) => {
      // 0 や null ではない、最後の有効なカテゴリを見つける
      const nonZeroCategory = category.filter(
        (item) => item !== 0 && item !== null && item !== undefined
      );
      return nonZeroCategory.length; // 有効なカテゴリの数を階層にする
    };

    // それぞれのカテゴリの階層を計算
    const amazonLevel = findLastLevel([
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6
    ]);
    const rakutenLevel = findLastLevel([
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5
    ]);
    const yahooLevel = findLastLevel([y_cate1, y_cate2, y_cate3, y_cate4]);

    // channel に応じてどのカテゴリの level を渡すか決定
    let categoryLevel = 0;
    if (channel === 1) {
      categoryLevel = amazonLevel;
    } else if (channel === 2) {
      categoryLevel = rakutenLevel;
    } else if (channel === 3) {
      categoryLevel = yahooLevel;
    }
    const res = await client.post(`${apiUrl}category/brand_item_share`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel,
      brand: brand,
      category_level: categoryLevel
    });
    return res.data;
  }
);

const brandShareSlice = createSlice({
  name: 'brandshare',
  initialState: {
    amazon_graphdata: {
      graphdata: {
        percentage: [],
        sales: [],
        label: []
      },
      item_share_data: {
        percentage: [],
        sales: [],
        item_id: [],
        product_name: []
      },
      item_share_table_data: []
    },
    rakuten_graphdata: {
      graphdata: {
        percentage: [],
        sales: [],
        label: []
      },
      item_share_data: {
        percentage: [],
        sales: [],
        item_id: [],
        product_name: []
      },
      item_share_table_data: []
    },
    yahoo_graphdata: {
      graphdata: {
        percentage: [],
        sales: [],
        label: []
      },
      item_share_data: {
        percentage: [],
        sales: [],
        item_id: [],
        product_name: []
      },
      item_share_table_data: []
    },
    month_share_data_list: [],
    isLoading: false,
    isListLoading: false,
    isItemShareLoading: false
  },
  reducers: {
    resetAgraph(state, action) {
      return {
        ...state,
        amazon_graphdata: {
          graphdata: {
            percentage: [],
            sales: [],
            label: []
          }
        }
      };
    },
    resetRgraph(state, action) {
      return {
        ...state,
        rakuten_graphdata: {
          graphdata: {
            percentage: [],
            sales: [],
            label: []
          }
        }
      };
    },
    resetYgraph(state, action) {
      return {
        ...state,
        yahoo_graphdata: {
          graphdata: {
            percentage: [],
            sales: [],
            label: []
          }
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandShareGraphData.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.amazon_graphdata['graphdata'] = action.payload.data;
        state.isLoading = false;
      } else if (action.payload.channel == 2) {
        state.rakuten_graphdata['graphdata'] = action.payload.data;
        state.isLoading = false;
      } else if (action.payload.channel == 3) {
        state.yahoo_graphdata['graphdata'] = action.payload.data;
        state.isLoading = false;
      }
    });
    builder.addCase(fetchBrandShareGraphData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchBrandShareGraphData.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });

    builder.addCase(fetchBrandShareListData.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.month_share_data_list = action.payload.month_data_list;
        state.isListLoading = false;
      } else if (action.payload.channel == 2) {
        state.month_share_data_list = action.payload.month_data_list;
        state.isListLoading = false;
      } else if (action.payload.channel == 3) {
        state.month_share_data_list = action.payload.month_data_list;
        state.isListLoading = false;
      }
    });
    builder.addCase(fetchBrandShareListData.pending, (state, action) => {
      return {
        ...state,
        isListLoading: true
      };
    });
    builder.addCase(fetchBrandShareListData.rejected, (state, action) => {
      return {
        ...state,
        isListLoading: false
      };
    });

    builder.addCase(fetchBrandItemShareData.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.amazon_graphdata['item_share_data'] = action.payload.data;
        state.amazon_graphdata['item_share_table_data'] =
          action.payload.table_data;
        state.isItemShareLoading = false;
      } else if (action.payload.channel == 2) {
        state.rakuten_graphdata['item_share_data'] = action.payload.data;
        state.rakuten_graphdata['item_share_table_data'] =
          action.payload.table_data;
        state.isItemShareLoading = false;
      } else if (action.payload.channel == 3) {
        state.yahoo_graphdata['item_share_data'] = action.payload.data;
        state.yahoo_graphdata['item_share_table_data'] =
          action.payload.table_data;
        state.isItemShareLoading = false;
      }
    });
    builder.addCase(fetchBrandItemShareData.pending, (state, action) => {
      return {
        ...state,
        isItemShareLoading: true
      };
    });
    builder.addCase(fetchBrandItemShareData.rejected, (state, action) => {
      return {
        ...state,
        isItemShareLoading: false
      };
    });
  }
});

export const selectAmazonBrandShareGraph = (state) =>
  state.brandshare.amazon_graphdata;
export const selectRakutenBrandShareGraph = (state) =>
  state.brandshare.rakuten_graphdata;
export const selectYahooBrandShareGraph = (state) =>
  state.brandshare.yahoo_graphdata;
export const monthlyShareDataList = (state) =>
  state.brandshare.month_share_data_list;
export const monthlygraphStatus = (state) => state.brandshare.isListLoading;
export const brandgraphShareStatus = (state) => state.brandshare.isLoading;
export const brandItemShareStatus = (state) =>
  state.brandshare.isItemShareLoading;

export const { resetAgraph, resetRgraph, resetYgraph } =
  brandShareSlice.actions;
export default brandShareSlice.reducer;

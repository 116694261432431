/* eslint-disable*/
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  Alert,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  fetchUser,
  setMailFlag,
  setSpAPIAuth,
  setYahooAPIAuth
} from 'feactures/user/userSlice';
import { fetchNews } from 'feactures/user/newsSlice';
import { fetchYahooKey } from 'feactures/user/yahooKeySlice';
import { changeChannel } from 'feactures/channel/channelSlice';
import {
  errorResMessage,
  errorResStatus,
  resetError
} from 'feactures/errorSlice';
import { fetchValidCategoryList } from 'feactures/category/validCategoryListSlice';
import './Login.css';
import { fetchEnv } from 'feactures/env/envSlice';
import { fetchFavoriteCategory } from 'feactures/category/favoriteCategorySlice';
import { resetKubunYearMonth } from 'feactures/section/sectionSlice';
// import { resetDisplayFlag } from 'feactures/survey/surveySlice';
import { setOtherSelectedBrandList } from 'feactures/brand/brandSlice';
import {
  changeAcate1,
  changeRcate1,
  changeYcate1
} from 'feactures/category/categoryGraphSlice';

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);

  const errorMessage = useSelector(errorResMessage);
  const errorStatus = useSelector(errorResStatus);

  const handleClose = () => {
    dispatch(resetError());
    setError(null);
  };

  const snackbarHandleClose = (event, reason) => {
    setSnackOpen(false);
  };

  const amazon_callback_uri = searchParams.get('amazon_callback_uri');
  const amazon_state = searchParams.get('amazon_state');
  const spapi_oauth_code = searchParams.get('spapi_oauth_code');
  const yahoo_oauth_code = searchParams.get('code');
  const from_report_mail = searchParams.get('from_mail');

  //paramにspapi_oauth_codeが含まれていたらauth_codeをflaskに
  useEffect(() => {
    if (spapi_oauth_code) {
      // console.log(spapi_oauth_code);
      dispatch(setSpAPIAuth(spapi_oauth_code));
      setSnackOpen(true);
    }
    //paramにcodeが含まれていたらyahooコード。flaskに
    if (yahoo_oauth_code) {
      dispatch(setYahooAPIAuth(yahoo_oauth_code));
      setSnackOpen(true);
    }
    //paramにfrom_report_mailが含まれていたら実績レポートメールからのアクセス。flaskに
    if (from_report_mail) {
      dispatch(setMailFlag(true));
    }
  }, [dispatch]);

  const onSubmit = async (values) => {
    setError(null);

    try {
      // fetchUserのディスパッチを待機し、結果を取り出す
      const action = await dispatch(fetchUser(values));

      // unwrapResultでpayloadを取得して成功時に処理を行う
      const result = unwrapResult(action);

      // fetchUserが成功した場合のみ以下の処理を実行
      if (result) {
        setSuccess(true);
        // paramにcallback_uriが含まれていたらリダイレクトさせる;
        if (amazon_callback_uri) {
          const de_uri = decodeURI(
            amazon_callback_uri +
              '?redirect_uri=https://www.oxcim.jp/login' +
              '&amazon_state=' +
              amazon_state
          );
          // console.log(de_uri);
          window.open(de_uri);
        }
        dispatch(fetchEnv());
        if (!action.payload.plan.amazon.premium) {
          dispatch(changeChannel({ id: 2, name: 'rakuten' }));
        }
        // dispatch(resetDisplayFlag());
        dispatch(fetchValidCategoryList());
        dispatch(fetchFavoriteCategory());
        dispatch(fetchNews());
        dispatch(fetchYahooKey());
        // action.payloadからブランド情報を取り出す;
        const brandData = action.payload.freePlanPreselectedItems.brand;
        const categoryData = action.payload.freePlanPreselectedItems.category;

        // brandSliceの状態を更新する
        dispatch(setOtherSelectedBrandList(brandData));

        //categorySliceの状態を更新する
        if (!action.payload.plan.amazon.premium && categoryData) {
          dispatch(changeAcate1(categoryData.amazon));
        }
        if (!action.payload.plan.rakuten.premium && categoryData) {
          dispatch(changeRcate1(categoryData.rakuten));
        }
        if (!action.payload.plan.yahoo.premium && categoryData) {
          dispatch(changeYcate1(categoryData.yahoo));
        }

        setTimeout(function () {
          navigate('/app/measureanalysis');
        }, 500);
      }
    } catch (err) {
      setError(err.message);
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              userId: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              userId: Yup.string()
                .max(255)
                .required('Login ID(メールアドレス)が未入力です'),
              // userId: Yup.string()
              // .email('ユーザーIDの形式が正しくありません')
              // .max(255)
              // .required('ユーザーID が未入力です'),
              password: Yup.string().max(255).required('パスワードが未入力です')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h2">ログイン</Typography>
                </Box>
                <TextField
                  error={Boolean(touched.userId && errors.userId)}
                  fullWidth
                  helperText={touched.userId && errors.userId}
                  label="Login ID(メールアドレス)"
                  margin="normal"
                  name="userId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="userId"
                  value={values.userId}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  autoComplete="off"
                />
                <Box sx={{ py: 2 }}>
                  {success ? (
                    <Alert severity="success">ログインに成功しました!</Alert>
                  ) : (
                    <Button
                      // color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      ログイン
                    </Button>
                  )}
                  {error && <Alert severity="error">{errorMessage}</Alert>}
                </Box>

                {/* <Typography color="text.secondary" variant="h5">
                  新規アカウント開設は{' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h5"
                    underline="hover"
                  >
                    こちら
                  </Link>
                </Typography> */}

                <Typography color="text.secondary" variant="h5">
                  パスワードを忘れた方は{' '}
                  <Link
                    component={RouterLink}
                    to="/reregister"
                    variant="h5"
                    underline="hover"
                  >
                    こちら
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
        <Box justifyContent="center" display="flex" mt={2}>
          <Typography variant="h5" fontWeight="bold">
            ログイン情報は、お申し込み完了後、登録されたメールアドレス宛にお送りしています。
            <br />
            ※届いていない場合、お手数ではございますが迷惑メールフォルダもご確認ください。
          </Typography>
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackOpen}
        autoHideDuration={3000}
      >
        <Alert
          onClose={() => {
            window.scrollTo(0, 0);
            snackbarHandleClose();
          }}
          severity="warning"
          sx={{ width: '100%' }}
        >
          承認を行います。再度ログインして認証を完了してください
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;

/* eslint-disable*/
import { Box, Typography, useTheme } from '@material-ui/core';
import {
  performanceSeoData,
  performanceSeoSetting
} from 'feactures/performancereview/performancereviewSlice';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

const SeoCompareLineGraph = () => {
  const [rotation, setRotation] = useState(0);
  const dialogRef = useRef(null); // ダイアログの幅を取得するための ref

  useEffect(() => {
    const updateRotation = () => {
      if (dialogRef.current) {
        const dialogWidth = dialogRef.current.offsetWidth; // ダイアログの幅を取得
        setRotation(dialogWidth < 900 ? 45 : 0); // 幅900px以下なら斜め、それ以上なら横向き
      }
    };

    window.addEventListener('resize', updateRotation);
    updateRotation(); // 初回実行

    return () => window.removeEventListener('resize', updateRotation);
  }, []);

  const setting = useSelector(performanceSeoSetting);
  const seodata = useSelector(performanceSeoData);

  const formattedYokoziku = seodata.yokoziku.map((date, index) => {
    const [month, day] = date.split('/'); // 'MM/DD' の形式に分解
    const monthName = `${parseInt(month, 10)}`;
    const dayName = `${parseInt(day, 10)}`;
    return `${monthName}/${dayName}`;
  });

  // 1日おきのラベルを作成
  const formattedDates = seodata.yokoziku.map((date, index) => {
    const [month, day] = date.split('/'); // 'MM/DD' の形式に分解
    const monthName = `${parseInt(month, 10)}`;
    const dayName = `${parseInt(day, 10)}`;

    return index % 2 === 0 ? `${monthName}/${dayName}` : ''; // ✅ 奇数日のみ表示
  });

  const theme = useTheme();
  let label = '';
  let organicColor = '';
  let sponsorColor = '';
  if (setting.compareType == 'own') {
    label = '自社';
    organicColor = '#ACABE3';
    sponsorColor = '#A3D7B5';
  } else if (setting.compareType == 'other') {
    label = '競合';
    organicColor = '#FFD88F';
    sponsorColor = '#FEA165';
  }

  const generateChartData = (label, color, dataset, max) => {
    return {
      datasets: [
        {
          label: `${label}`,
          data: dataset.map((x) => {
            if (x === -99)
              return label.includes('オーガニック') ? max + 40 : max + 5; // 圏外：オーガニック最大値+50 、広告最大値 + 5
            if (x === 0) return null; // データなし：０
            return Number(x);
          }),
          backgroundColor: color,
          borderColor: color,
          pointRadius: 3,
          pointStyle: 'circle',
          pointBorderColor: color,
          pointBackgroundColor: color,
          lineTension: 0.2
        }
      ],
      labels: formattedDates
    };
  };

  const dataOrganic = generateChartData(
    `${label}オーガニック`,
    organicColor,
    seodata.organic,
    240
  );
  const dataSponsor = generateChartData(
    `${label}広告`,
    sponsorColor,
    seodata.sponsor,
    20
  );

  const generateChartOptions = (min, max, label, stepSize) => ({
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: rotation,
          minRotation: rotation,
          padding: 10,
          font: {
            size: '10px'
          }
        },
        grid: { display: false, drawBorder: false },
        title: { display: false }
      },
      y: {
        suggestedMin: 1,
        max: stepSize == 40 ? 290 : 25,
        reverse: true,
        ticks: {
          maxTicksLimit: 20,
          stepSize: stepSize,
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          },
          callback: function (value) {
            if (value === 1) return '1位';
            if (stepSize == 40 && value == 280) return '圏外';
            if (stepSize == 5 && value == 25) return '圏外';
            return value > 0 ? `${value}位` : ''; // 整数でない場合は表示しない
          }
        },
        afterBuildTicks: function (scale) {
          // 圏外と1位のデフォルト設定
          let newTicks = [
            { label: '圏外', value: stepSize == 40 ? 280 : 25 }, // 圏外を追加
            ...scale.ticks.slice(1, -1), // 既存の ticks を保持
            { label: '1位', value: 1 } // 1位を設定
          ];

          scale.ticks = newTicks;
        },
        grid: {
          display: true,
          borderDash: [2],
          drawBorder: false
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            // X軸の省略されたラベルではなく、元データ `seodata.yokoziku` から正しい日付を取得
            const index = tooltipItems[0].dataIndex;
            return formattedYokoziku[index]; // ✅ すべての日付をツールチップに表示
          },
          label: (tooltipItem) => {
            let value = tooltipItem.raw;
            if (value > max) return `${tooltipItem.dataset.label}: 圏外`;
            if (value === 0) return `${tooltipItem.dataset.label}: データ無し`;
            return `${tooltipItem.dataset.label}: ${value}位`;
          }
        }
      },
      legend: { position: 'bottom' },
      datalabels: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 20,
    layout: {
      padding: {
        top: 30
      }
    }
  });

  const optionsOrganic = generateChartOptions(
    1,
    240,
    `${label}オーガニック`,
    40
  );
  const optionsSponsor = generateChartOptions(1, 20, `${label}広告`, 5);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
        minHeight="3rem"
      >
        <Typography fontWeight="bold">{setting.keyword}</Typography>
      </Box>

      <Box
        display="flex"
        width="100%"
        height="100%"
        maxHeight="88%"
        flexDirection="row"
        paddingX="1rem"
        ref={dialogRef}
      >
        {/* オーガニックのセクション */}
        <Box display="flex" flexDirection="column" width="50%" mr="1%">
          <Box bgcolor="#F8FAFB" p="4px" mb={1}>
            <Box
              display="flex"
              justifyContent="center"
              minHeight="32px"
              flexDirection="column"
            >
              <Typography color="text.secondary" lineHeight={1}>
                {label}オーガニック
              </Typography>
              <Box
                display="flex"
                flex="0 0 auto"
                alignItems="baseline"
                justifyContent="center"
              >
                {seodata.organicRank == 0 ? (
                  <Typography
                    color="text.secondary"
                    fontSize="14px"
                    lineHeight={1}
                  >
                    圏外
                  </Typography>
                ) : (
                  <>
                    <Typography mr="4px" color="text.secondary" lineHeight={1}>
                      月平均
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      fontSize="20px"
                      lineHeight={1}
                    >
                      {seodata.organicRank}
                    </Typography>
                    <Typography ml="4px" color="text.secondary" lineHeight={1}>
                      位
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box flexGrow={1} height="100%">
            <Line data={dataOrganic} options={optionsOrganic} />
          </Box>
        </Box>

        {/* 広告のセクション */}
        <Box display="flex" flexDirection="column" width="50%">
          <Box bgcolor="#F8FAFB" p="4px" mb={1}>
            <Box
              display="flex"
              justifyContent="center"
              minHeight="32px"
              flexDirection="column"
            >
              <Typography color="text.secondary" lineHeight={1}>
                {label}広告
              </Typography>
              <Box
                display="flex"
                flex="0 0 auto"
                alignItems="baseline"
                justifyContent="center"
              >
                {seodata.sponsorRank == 0 ? (
                  <Typography
                    color="text.secondary"
                    fontSize="14px"
                    lineHeight={1}
                  >
                    圏外
                  </Typography>
                ) : (
                  <>
                    <Typography mr="4px" color="text.secondary" lineHeight={1}>
                      月平均
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      fontSize="20px"
                      lineHeight={1}
                    >
                      {seodata.sponsorRank}
                    </Typography>
                    <Typography ml="4px" color="text.secondary" lineHeight={1}>
                      位
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box flexGrow={1} height="100%">
            <Line data={dataSponsor} options={optionsSponsor} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SeoCompareLineGraph;

/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useEffect, memo, useCallback, useRef } from 'react';
import { makeStyles, styled } from '@material-ui/styles';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userinfo, userFirstLogin } from 'feactures/user/userSlice';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const steps = [
  'アカウント作成',
  '審査中\n(約2営業日)',
  'データ接続\n(審査期間中も設定可)',
  'Oxcim利用可能'
];

const useStyles = makeStyles(() => ({
  root: {
    '& .Mui-active > .MuiStepConnector-line': { borderColor: '#728DC4' },
    '& .Mui-completed > .MuiStepConnector-line': { borderColor: '#728DC4' }
  }
}));

const ConnectionBoxBlue = styled(Box)(({ theme }) => ({
  borderRadius: '4px',
  width: '230px',
  height: '150px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#728DC4',
  boxShadow: '2px 3px 5px #425272',
  position: 'relative',
  '&:hover': {
    backgroundColor: '#839ac7'
  }
}));

const StyledStepIcon = ({ active, completed, className, icon }) => {
  return completed ? (
    <TaskAltIcon sx={{ color: '#728DC4' }} />
  ) : active ? (
    <Box
      sx={{
        color: '#728DC4',
        borderRadius: '50%',
        border: '1px solid #728DC4',
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography>{icon}</Typography>
    </Box>
  ) : (
    <Box
      sx={{
        color: '#929292',
        borderRadius: '50%',
        border: '1px solid #929292',
        width: '25px',
        height: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography>{icon}</Typography>
    </Box>
  );
};

const JudgeFailedModal = () => {
  const c = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userinfo);
  const firstLogin = useSelector(userFirstLogin);
  const amazonStatus = user.amazonStatus;
  const rakutenStatus = user.rakutenStatus;
  const yahooStatus = user.yahooStatus;
  const [stepValue, setStepValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [modalNum, setModalNum] = useState(1);

  const handleOpen = (num) => {
    setModalNum(num);
    setOpen(true);
  };

  return (
    <Box sx={{ height: '450px', width: '950px', padding: '10px' }}>
      <Container sx={{ width: 'auto' }}>
        <Box sx={{ mb: 1, fontWeight: 'bold' }}>
          <Stepper activeStep={stepValue} alternativeLabel className={c.root}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  style={{ whiteSpace: 'pre-line' }}
                  StepIconComponent={StyledStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            my: 3,
            textAlign: 'center'
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: '30px' }} />
          <Typography fontSize={'16px'} fontWeight={'bold'} sx={{ my: 2 }}>
            審査についてのご案内
          </Typography>
          <Box sx={{ maxWidth: 500 }}>
            <Typography fontSize={'14px'}>
              現在、審査の結果を確認中です。
              <br />
              審査には1〜2営業日ほどお時間がかかる場合があります。
              <br />
              審査完了後、Oxcimよりメールで結果をご連絡いたしますので、少々お待ちください。
            </Typography>

            {/* <Typography fontSize={'14px'} sx={{ my: 2 }}>
              なお、審査後はデータ接続が必要となります。データ接続は審査期間中にも設定可能ですので、お早目に接続設定をお願いいたします。
              <br />
              <a
                href="https://www.oxcim.net/setting"
                target="_blank"
                rel="noopener noreferrer"
              >
                データ接続はこちらから
              </a>
              <br />
            </Typography> */}
          </Box>

          <Typography fontSize={'12px'} sx={{ my: 3, color: 'gray' }}>
            ご不明な点や追加のご質問がある場合は、
            <br />
            <a href="mailto:introduction_oxcim@mg.opt.ne.jp">
              introduction_oxcim@mg.opt.ne.jp
            </a>
            までお気軽にお問い合わせください。
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default JudgeFailedModal;

/* eslint-disable*/
import * as React from 'react';
import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuList from '@mui/material/MenuList';
import Menu from '@mui/material/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { SetMealRounded } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// const pages = [
//   { name: 'KPIビュー', path: '/kpiview', sub: true },
//   // {
//   //   name: 'KPIモニタリング',
//   //   path: '/kpi',
//   //   sub: true //サブメニューの有り無し
//   // },
//   {
//     name: 'マーケットビュー',
//     path: '/market',
//     sub: true
//   }
//   // {
//   //   name: 'ROIビュー',
//   //   path: '/Roi',
//   //   sub: false
//   // }
// ];

// const kpisubmenu = [
//   {
//     subMenu: 'KPIモニタリング',
//     path: '/monitoring',
//     sub: false //サブメニューの有り無し
//   },
//   {
//     subMenu: 'SubKPIモニタリング',
//     childs: [
//       // { page: 'プロセスKPI', path: '/processkpi' },
//       { page: '閲覧数', path: '/numberviews' },
//       { page: 'CVR', path: '/cvr' },
//       { page: '販売価格', path: '/unitprice' }
//     ],
//     sub: true
//   }
//   // childs: [
//   //   { page: 'カテゴリTOP100', path: '/categoryboard' },
//   //   // { page: 'キーワード検索量', path: '/market/keywordVolume' },
//   //   { page: '間接流通', path: '/ownproductboard' }
//   // ]
// ];

// const marketsubmenu = [
//   {
//     subMenu: '市場',
//     childs: [
//       { page: 'カテゴリTOP100', path: '/categoryboard' },
//       // { page: 'キーワード検索量', path: '/market/keywordVolume' },
//       { page: '間接流通', path: '/ownproductboard' }
//     ],
//     sub: true
//   },
//   {
//     subMenu: '競合',
//     childs: [
//       { page: '競合商品', path: '/otherproductboard' },
//       { page: 'カテゴリNo.1売上', path: '/category1/sales' }
//     ],
//     sub: true
//   }
// ];

const MenuNavbar2 = () => {
  const navigate = useNavigate();
  const [kpiAnchorEl, setKpiAnchorEl] = useState(null);
  const [subKpiAnchorEl, setSubKpiAnchorEl] = useState(null);

  const [marketAnchorEl, setMarketAnchorEl] = useState(null);
  const [subMarketAnchorEl, setSubMarketAnchorEl] = useState(null);
  const [subBenchAnchorEl, setSubBenchAnchorEl] = useState(null);

  const kpiHandleClick = (event) => {
    console.log('kpihandleclick');
    if (kpiAnchorEl !== event.currentTarget) {
      setKpiAnchorEl(event.currentTarget);
    }
  };

  const subkpiHandleClick = (event) => {
    console.log(subKpiAnchorEl);
    console.log(event.currentTarget);
    console.log('subkpihandleclick');
    if (subKpiAnchorEl !== event.currentTarget) {
      setSubKpiAnchorEl(event.currentTarget);
    }
  };

  const subkpiHandleClose = () => {
    console.log('subkpihandleclose');
    setSubKpiAnchorEl(null);
  };
  const kpiHandleClose = () => {
    console.log('kpihandleclose');
    subkpiHandleClose();
    setKpiAnchorEl(null);
  };

  const marketHandleClick = (event) => {
    console.log('marketHandleClick');
    if (marketAnchorEl !== event.currentTarget) {
      setMarketAnchorEl(event.currentTarget);
    }
  };

  const marketHandleClose = () => {
    console.log('marketHandleClose');
    setMarketAnchorEl(null);
  };

  const subMarketHandleClick = (event) => {
    console.log(subMarketAnchorEl);
    console.log(event.currentTarget);
    console.log('subMarketHandleClick');
    if (subMarketAnchorEl !== event.currentTarget) {
      setSubMarketAnchorEl(event.currentTarget);
    }
  };

  const subMarketHandleClose = () => {
    console.log('subMarketHandleClose');
    setSubMarketAnchorEl(null);
  };

  const allhandleClose = () => {
    console.log('全部閉じます');
    marketHandleClose();
    subkpiHandleClose();
    kpiHandleClose();
  };

  //ページ遷移
  const handleOpenPage = (path, event) => {
    console.log('遷移');
    allhandleClose();
    navigate(`/app${path}`);
  };

  return (
    <>
      <Box
        sx={{
          height: '50px',
          pl: 5,
          flexGrow: 1,
          display: 'flex'
        }}
      >
        <Button
          aria-owns={kpiAnchorEl ? 'sub-kpi-menu' : undefined}
          aria-haspopup="true"
          onClick={kpiHandleClick}
          //   onClose={kpiHandleClose}
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            minWidth: '180px'
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          KPIビュー
        </Button>
        <Menu
          id="sub-kpi-menu"
          anchorEl={kpiAnchorEl}
          open={Boolean(kpiAnchorEl)}
          onClose={kpiHandleClose}
        >
          <MenuItem onClick={(e) => handleOpenPage('/monitoring', e)}>
            KPIモニタリング
          </MenuItem>
          <MenuItem
            aria-owns={subKpiAnchorEl ? 'mago-kpi-menu' : undefined}
            aria-haspopup="true"
            onMouseEnter={subkpiHandleClick}
            // MenuListProps={{ onMouseOver: subkpiHandleClose }} //hoverしてないときはsubkpiをとじる
            // MenuListProps={{ onMouseLeave: subkpiHandleClose }} //hoverしてないときはsubkpiをとじる
          >
            SubKpiモニタリング
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              <ArrowForwardIosIcon fontSize="10px" />
            </ListItemIcon>
          </MenuItem>
        </Menu>
        <Menu
          sx={{
            opacity: 1
          }}
          id="mago-kpi-menu"
          anchorEl={subKpiAnchorEl}
          open={Boolean(subKpiAnchorEl)}
          onMouseLeave={subkpiHandleClose}
          onClose={subkpiHandleClose}
          MenuListProps={{ onMouseLeave: subkpiHandleClose }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem onClick={(e) => handleOpenPage('/numberviews', e)}>
            閲覧数
          </MenuItem>
          <MenuItem onClick={(e) => handleOpenPage('/cvr', e)}>CVR</MenuItem>
          <MenuItem onClick={(e) => handleOpenPage('/unitprice', e)}>
            販売単価
          </MenuItem>
        </Menu>

        <Button
          aria-owns={marketAnchorEl ? 'sub-market-menu' : undefined}
          aria-haspopup="true"
          onClick={marketHandleClick}
          onMouseOver={marketHandleClick}
          sx={{
            color: 'text.secondary',
            fontWeight: 'bold',
            minWidth: '180px'
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          マーケットビュー
        </Button>

        <Menu
          id="sub-market-menu"
          anchorEl={marketAnchorEl}
          open={Boolean(marketAnchorEl)}
          onClose={marketHandleClose}
        >
          <MenuItem
            aria-owns={subKpiAnchorEl ? 'sizyo-menu' : undefined}
            aria-haspopup="true"
            onMouseEnter={subkpiHandleClick}
            MenuListProps={{ onMouseLeave: subkpiHandleClose }} //hoverしてないときはsubkpiをとじる
          >
            市場
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              <ArrowForwardIosIcon fontSize="10px" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            aria-owns={subKpiAnchorEl ? 'kyougou-menu' : undefined}
            aria-haspopup="true"
            onMouseEnter={subkpiHandleClick}
            MenuListProps={{ onMouseLeave: subkpiHandleClose }} //hoverしてないときはsubkpiをとじる
          >
            競合
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              <ArrowForwardIosIcon fontSize="10px" />
            </ListItemIcon>
          </MenuItem>
        </Menu>

        <Menu
          sx={{
            opacity: 1
          }}
          id="sizyo-menu"
          anchorEl={subKpiAnchorEl}
          open={Boolean(subKpiAnchorEl)}
          onMouseLeave={subkpiHandleClose}
          onClose={subkpiHandleClose}
          MenuListProps={{ onMouseLeave: subkpiHandleClose }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem onClick={(e) => handleOpenPage('/numberviews', e)}>
            カテゴリTOP１００
          </MenuItem>
          <MenuItem onClick={(e) => handleOpenPage('/cvr', e)}>
            間接流通
          </MenuItem>
        </Menu>
        <Menu
          sx={{
            opacity: 1
          }}
          id="sizyo-menu"
          anchorEl={subKpiAnchorEl}
          open={Boolean(subKpiAnchorEl)}
          onMouseLeave={subkpiHandleClose}
          onClose={subkpiHandleClose}
          MenuListProps={{ onMouseLeave: subkpiHandleClose }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem onClick={(e) => handleOpenPage('/cvr', e)}>
            商品分析
          </MenuItem>
          <MenuItem onClick={(e) => handleOpenPage('/numberviews', e)}>
            カテゴリNo.1商品
          </MenuItem>
        </Menu>
        {/* <Menu
          id="sub-market-menu"
          anchorEl={marketAnchorEl}
          open={Boolean(marketAnchorEl)}
          onClose={marketHandleClose}
          MenuListProps={{ onMouseLeave: marketHandleClose }}
        >
          <MenuItem onClick={marketHandleClose}>市場</MenuItem>
          <MenuItem onClick={marketHandleClose}>競合</MenuItem>
        </Menu> */}
      </Box>
    </>
  );
};
export default MenuNavbar2;

/* eslint-disable*/
import { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Typography
} from '@material-ui/core';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch, useSelector } from 'react-redux';

import {
  amazonFavoriteCategory,
  rakutenFavoriteCategory,
  yahooFavoriteCategory,
  updateFavoriteCategory
} from 'feactures/category/favoriteCategorySlice';
import {
  currentAmazonCategoryList,
  currentRakutenCategoryList,
  currentYahooCategoryList
} from 'feactures/category/categoryGraphSlice';
import {
  validAmazonCategoryList,
  validRakutenCategoryList,
  validYahooCategoryList
} from 'feactures/category/validCategoryListSlice';

const ComboBox = (props) => {
  const { inputLabel, items, channel, onChange, defau } = props; // eslint-disable-line

  const dispatch = useDispatch();
  const favoriteAmazonCategory = useSelector(amazonFavoriteCategory);
  const favoriteRakutenCategory = useSelector(rakutenFavoriteCategory);
  const favoriteYahooCategory = useSelector(yahooFavoriteCategory);

  // 現在選択中のカテゴリ
  const currentSelectAmazonCategories = useSelector(currentAmazonCategoryList);
  const currentSelectRakutenCategories = useSelector(
    currentRakutenCategoryList
  );
  const currentSelectYahooCategories = useSelector(currentYahooCategoryList);
  let currentSelectCategoryList = [];

  // disabledにしないカテゴリのリスト
  const rakutenCategoryList = useSelector(validRakutenCategoryList);
  const amazonCategoryList = useSelector(validAmazonCategoryList);
  const yahooCategoryList = useSelector(validYahooCategoryList);

  if (channel == 1) {
    currentSelectCategoryList = currentSelectAmazonCategories;
  } else if (channel == 2) {
    currentSelectCategoryList = currentSelectRakutenCategories;
  } else if (channel == 3) {
    currentSelectCategoryList = currentSelectYahooCategories;
  }

  const isCategory1 = inputLabel == 'カテゴリ1';
  const isCategory2 = inputLabel == 'カテゴリ2';
  const isCategory3 = inputLabel == 'カテゴリ3';
  const isCategory4 = inputLabel == 'カテゴリ4';
  const isCategory5 = inputLabel == 'カテゴリ5';
  const isCategory6 = inputLabel == 'カテゴリ6';

  // カテゴリ1ならcurrentSelectCategoryListの0番目を取得する、といったように、カテゴリごとに適切な値を取得する
  let currentSelectCategory = null;
  for (let i = 1; i <= 6; i++) {
    let isCategory = eval('isCategory' + i);
    if (isCategory) {
      currentSelectCategory = currentSelectCategoryList[i - 1];
      break;
    }
  }

  // Selectコンポーネントで使用されているvalueと、MenuItemコンポーネントで使用されているvalueが一致していないと画面に表示されないので、matchingItemで一致させている
  let matchingItem = null;
  items.forEach((item) => {
    if (item.value === currentSelectCategory.value) {
      matchingItem = item;
    }
  });

  if (matchingItem !== null) {
    currentSelectCategory = matchingItem;
  }
  // 星マークを押すとdbに保存し、再度dbからお気に入りカテゴリを取得
  const handleClickStar = async (item, event) => {
    if (event) {
      event.stopPropagation();
    }
    if (item.value) {
      dispatch(
        updateFavoriteCategory({
          category_id: item.value,
          category_name: item.label,
          status: isItemRegistered(item) ? 0 : 1
        })
      );
    } else if (currentSelectCategory?.value) {
      dispatch(
        updateFavoriteCategory({
          category_id: currentSelectCategory.value,
          category_name: currentSelectCategory.label,
          status: isRegistered ? 0 : 1
        })
      );
    }
  };

  // お気に入り登録されているか
  const isRegistered =
    (channel == 1 &&
      favoriteAmazonCategory.some(
        (item) => item.value === currentSelectCategory.value
      )) ||
    (channel == 2 &&
      favoriteRakutenCategory.some(
        (item) => item.value === currentSelectCategory.value
      )) ||
    (channel == 3 &&
      favoriteYahooCategory.some(
        (item) => item.value === currentSelectCategory.value
      ));

  const isItemRegistered = (selectItem) => {
    return (
      (channel == 1 &&
        favoriteAmazonCategory.some(
          (item) => item.value === selectItem.value
        )) ||
      (channel == 2 &&
        favoriteRakutenCategory.some(
          (item) => item.value === selectItem.value
        )) ||
      (channel == 3 &&
        favoriteYahooCategory.some((item) => item.value === selectItem.value))
    );
  };

  const isRakuten = channel == 2;
  const disableCondition = isCategory6 && isRakuten; //category_4でrakuten_top100テーブルを取得できるまでdisable

  return (
    <Box
      sx={
        disableCondition ? { display: 'none' } : { minWidth: 400, mb: 2, m: 2 }
      }
    >
      <FormControl fullWidth>
        {/* <InputLabel>{inputLabel}</InputLabel> */}
        <Typography color="#757575">{inputLabel}</Typography>
        <Box sx={{ position: 'relative' }}>
          <Select
            fullWidth
            value={currentSelectCategory || ''}
            onChange={(e) => onChange(e.target.value)}
            disabled={items.length === 0}
          >
            {items.map((item, index) => {
              return (
                <MenuItem
                  disabled={
                    (channel == 2 &&
                      !rakutenCategoryList.includes(item.value)) ||
                    (channel == 1 && !amazonCategoryList.includes(item.value))
                  }
                  value={item}
                  key={item.value}
                >
                  {item.label}
                  {isItemRegistered(item) ? (
                    <StarIcon
                      onClick={(e) => handleClickStar(item, e)}
                      fontSize="medium"
                      sx={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '50%',
                        right: '10%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: '10'
                      }}
                    />
                  ) : (
                    <StarBorderIcon
                      onClick={(e) => handleClickStar(item, e)}
                      fontSize="medium"
                      sx={{
                        cursor: currentSelectCategory?.value && 'pointer',
                        position: 'absolute',
                        top: '50%',
                        right: '10%',
                        transform: 'translate(-50%, -50%)',
                        stroke: '#ffffff',
                        strokeWidth: 1,
                        zIndex: '10'
                      }}
                    />
                  )}
                </MenuItem>
              );
            })}
          </Select>
          {currentSelectCategory !== 0 && (
            <>
              {isRegistered ? (
                <StarIcon
                  onClick={handleClickStar}
                  fontSize="medium"
                  sx={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '50%',
                    right: '10%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '100'
                  }}
                />
              ) : (
                <StarBorderIcon
                  onClick={handleClickStar}
                  fontSize="medium"
                  sx={{
                    cursor: currentSelectCategory?.value && 'pointer',
                    position: 'absolute',
                    top: '50%',
                    right: '10%',
                    transform: 'translate(-50%, -50%)',
                    stroke: '#ffffff',
                    strokeWidth: 1,
                    opacity: currentSelectCategory?.value ?? '0.3',
                    zIndex: '100'
                  }}
                />
              )}
            </>
          )}
        </Box>
        {Boolean(defau) == false && (
          <FormHelperText sx={{ ml: 0 }}>
            カテゴリを選択してください
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default ComboBox;

/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useEffect, memo, useCallback, useRef } from 'react';
import { makeStyles, styled } from '@material-ui/styles';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccountStatus } from 'feactures/user/userSlice';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const steps = [
  'アカウント作成',
  '審査中\n(約2営業日)',
  'データ接続\n(審査期間中も設定可)',
  'Oxcim利用可能'
];
const amazonSteps = [
  '承認設定',
  '承認完了',
  'データ取得中\n(約1時間)',
  '接続完了'
];
const rakutenSteps = [
  'ユーザー追加',
  '承認(弊社)',
  '権限付与',
  'API権限設定',
  '情報登録',
  'データ取得中\n(約1営業日)',
  '接続完了'
];
const yahooSteps = [
  'アカウント作成',
  'ユーザー追加',
  '権限付与\nID連携',
  'Oxcim承認',
  '承認完了',
  'データ取得中\n(約1営業日)',
  '接続完了'
];

// amazonStatus：
// 1-3：承認完了、データ取得中
// 4：データ取得が完了したとき
// 審査の状況で
// 5:メール送付完了

// rakutenStatus  1-2：ユーザー追加の承認が完了したとき(鈴木さん実装分）
// 3-5：API連携設定が完了したとき　(初回登録完了のメール送信前に5にする)
// 6：データ取得中のとき(今はまだ手動だが、データ取得中past_report開始時に6)
// 7：データ取得が完了(データ取得完了時に7にしてかつ審査ステータスをみる。完了時に審査
// 8: メール送付完了

// ヤフー：
// 1-5：承認が完了したとき
// 6：データ取得中のとき
// 7：データ取得が完了したとき
// 8: メール送付完了

const useStyles = makeStyles(() => ({
  root: {
    '& .Mui-active > .MuiStepConnector-line': { borderColor: '#728DC4' },
    '& .Mui-completed > .MuiStepConnector-line': { borderColor: '#728DC4' }
  }
}));

const AutoJudgeModal = () => {
  const c = useStyles();
  const dispatch = useDispatch();
  const [count, setCount] = useState(10); // 10秒のカウントダウン
  const [stepValue, setStepValue] = useState(1);

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => {
        if (count == 1) {
          dispatch(fetchAccountStatus());
        }
        setCount(count - 1);
      }, 1000); // 1秒ごとにカウントダウン

      return () => clearTimeout(timer);
    }
  }, [count]);

  function StyledStepIcon(props) {
    const { active, completed, className } = props;

    return completed ? (
      <TaskAltIcon
        sx={{
          color: '#728DC4'
        }}
      />
    ) : active ? (
      <Box
        sx={{
          color: '#728DC4',
          borderRadius: '50%',
          border: '1px solid #728DC4',
          width: '25px',
          height: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>{props.icon}</Typography>
      </Box>
    ) : (
      <Box
        sx={{
          color: '#929292',
          borderRadius: '50%',
          border: '1px solid #929292',
          width: '25px',
          height: '25px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography>{props.icon}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '450px',
        width: '950px',
        padding: '10px'
      }}
    >
      <Container sx={{ width: 'auto' }}>
        <Box sx={{ mb: 1, fontWeight: 'bold' }}>
          <Stepper activeStep={stepValue} alternativeLabel className={c.root}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  style={{ whiteSpace: 'pre-line' }}
                  StepIconComponent={StyledStepIcon}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            my: 10
          }}
        >
          <Typography fontSize={'16px'} fontWeight={'bold'}>
            審査中
          </Typography>
          <Box sx={{ position: 'relative', height: '75px' }}>
            <CircularProgress
              size={'40px'}
              variant="determinate"
              sx={{ color: 'black', my: 2 }}
              value={100 - 10 * count}
            />
            <Box
              sx={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography sx={{ color: 'black' }}>{count}</Typography>
            </Box>
          </Box>
          <Typography fontSize={'14px'}>
            お客様の申請を慎重に確認しております。
            <br />
            しばらくお待ちください。
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default AutoJudgeModal;

/* eslint-disable */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const deleteProducts = createAsyncThunk(
  'logical_delete/post',
  async (_, thunkAPI) => {
    const { userId, accountId, accountName } = thunkAPI.getState().user;
    const { channel, channelName } = thunkAPI.getState().currentchannel;
    const { deleteList } = thunkAPI.getState().deleteproducts;

    //バリエーション商品のjanは排除して削除
    const isJan = (key) => key && !isNaN(Number(key)) === false;
    const filteredList = deleteList.filter((key) => !isJan(key));

    const res = await client.post(`${apiUrl}logical_delete`, {
      user_id: userId,
      channel: channel,
      channel_name: channelName,
      account_id: accountId,
      delete_list: filteredList
    });
    return res.data;
  }
);

const deleteProductSlice = createSlice({
  name: 'delete',
  initialState: {
    deleteList: ['']
  },
  reducers: {
    addDeleteItem(state, action) {
      return {
        ...state,
        deleteList: action.payload.deleteList
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(deleteProducts.fulfilled, (state, action) => {
      return {
        ...state,
        deleteList: []
      };
    });
  }
});

export const { addDeleteItem } = deleteProductSlice.actions;
export const deleteProductsList = (state) => state.deleteproducts.deleteList;

export default deleteProductSlice.reducer;
